import { getStringFromFHIR, DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import React, { useState, useEffect, useContext } from 'react';
import { getJsonFromIdentifier } from './ResourceDictionaryFunctions';
import FevirContext from './FevirContext';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const PopulationSectionDisplay = ({ populationSection, resourceDictionary }) => {
  let studyGroupJson = getJsonFromIdentifier(resourceDictionary, populationSection.entry, "Group");
  let populationQuantity;
  let populationCombinationMethod;
  if (studyGroupJson) {
    if (studyGroupJson.quantity) {
      populationQuantity = studyGroupJson.quantity;
    }
    if (studyGroupJson.combinationMethod) {
      populationCombinationMethod = studyGroupJson.combinationMethod;
      if (studyGroupJson.combinationThreshold) {
        populationCombinationMethod += " " + studyGroupJson.combinationThreshold.toString();
      }
    }
  }
  let populationSummary = populationSection.text?.div || studyGroupJson?.description;
  let populationReference;
  if (populationSection.entry?.length > 0) {
    populationReference = populationSection.entry[0];
  }
  let sectionAuthor;
  if (Array.isArray(populationSection.author) && populationSection.author.length > 0) {
    sectionAuthor = populationSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }

  return <div>
    {(populationSummary || populationReference) ? <>
      {populationSummary && <div><br /><DisplayFromFHIR xhtml={populationSummary} /><br /><br /></div>}
      {populationQuantity && <div><br /><b>Quantity: </b>{populationQuantity}</div>}
      {populationCombinationMethod && <div><b>Combination Method: </b>{populationCombinationMethod}</div>}
      {studyGroupJson?.characteristic &&
        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={studyGroupJson} />}
      {populationReference && <div><br /><b>Reference to {populationReference?.type && populationReference.type + " "}Resource: </b><DisplayFromFHIR reference={populationReference} /></div>}
      {sectionAuthor && sectionAuthor}
      {populationSection.section?.[0] && <div>
        <div>
          <p><b>Population Subgroups:</b></p>
          <div style={{ marginLeft: "24px" }}>
            {(populationSection.section[0].text && Object.keys(populationSection.section[0].text).length > 0) && <div>
              {populationSection.section[0].text.status === "empty" ?
                <></>
                :
                <div>
                  <b>Narrative Summary: </b>
                  <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                    <DisplayFromFHIR xhtml={populationSection.section[0].text.div} />
                  </div>
                </div>}
              <br />
            </div>}
            {populationSection.section[0].orderedBy && <span>
              <b>Ordered by: </b>
              <DisplayFromFHIR codeableConcept={populationSection.section[0].orderedBy} /><br />
            </span>}
            {(Array.isArray(populationSection.section[0].entry) && populationSection.section[0].entry.length > 0) &&
              populationSection.section[0].entry.map((entry, entryIndex) => {
                return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
              })}
            {populationSection.section[0].emptyReason && <span>
              <b>Empty Reason: </b>
              <DisplayFromFHIR codeableConcept={populationSection.section[0].emptyReason} /><br />
            </span>}
          </div>
        </div>
      </div>}
    </>
      :
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={populationSection.emptyReason} />
      </>}
  </div>
}

const ResearchStudySectionDisplay = ({ researchStudySection, resourceDictionary }) => {
  let studyCitationJson;
  let studyResearchStudyJson;
  if (Array.isArray(researchStudySection.entry) && researchStudySection.entry.length > 0 &&
    researchStudySection.entry[0].type) {
    if (researchStudySection.entry[0].type === "Citation") {
      studyCitationJson = getJsonFromIdentifier(resourceDictionary, researchStudySection.entry, "Citation");
    } else if (researchStudySection.entry[0].type === "ResearchStudy") {
      studyResearchStudyJson = getJsonFromIdentifier(resourceDictionary, researchStudySection.entry, "ResearchStudy");
    }
  } else {
    let tempJson = getJsonFromIdentifier(resourceDictionary, researchStudySection.entry);
    if (tempJson?.resourceType === "ResearchStudy") {
      studyResearchStudyJson = tempJson;
    } else if (tempJson?.resourceType === "Citation") {
      studyCitationJson = tempJson;
    }
  }

  let researchStudySummary = researchStudySection.text?.div;
  if (!researchStudySummary) {
    if (studyCitationJson?.title) {
      researchStudySummary = "Research Study available: " + studyCitationJson.title;
    }
    if (studyResearchStudyJson) {
      let title = studyResearchStudyJson.title || studyResearchStudyJson.name || "";
      let pTitle = "";
      if (title) {
        pTitle = "<p><b>Title:</b> " + title + "</p>";
      }
      let description = studyResearchStudyJson.descriptionSummary || studyResearchStudyJson.description || "";
      let pDescription = "";
      if (description) {
        pDescription = "<br/><p><b>Description:</b> " + description + "</p>";
      }
      let phase = "";
      let pPhase = "";
      if (studyResearchStudyJson.phase) {
        phase = getStringFromFHIR.CodeableConcept(studyResearchStudyJson.phase);
      }
      if (phase) {
        pPhase = "<br/><p><b>Phase:</b> " + phase + "</p>";
      }
      let studyDesign = "";
      let pStudyDesign = "";
      if (studyResearchStudyJson.studyDesign) {
        studyDesign = studyResearchStudyJson.studyDesign.map(item => { return getStringFromFHIR.CodeableConcept(item) }).join("; ");
      }
      if (studyDesign) {
        pStudyDesign = "<br/><p><b>Study Design:</b> " + studyDesign + "</p>";
      }
      let combinedPstrings = pTitle + pDescription + pPhase + pStudyDesign;
      let narrative = "";
      if (combinedPstrings) {
        narrative = "<div>" + combinedPstrings + "</div>";
      }
      if (narrative) {
        researchStudySummary = narrative;
      }
    }
  }
  let researchStudyReference;
  if (researchStudySection.entry?.length > 0) {
    researchStudyReference = researchStudySection.entry[0];
  }
  let sectionAuthor;
  if (Array.isArray(researchStudySection.author) && researchStudySection.author.length > 0) {
    sectionAuthor = researchStudySection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }

  return <div>
    {(researchStudySummary || researchStudyReference) ? <>
      {researchStudySummary && <div><br /><DisplayFromFHIR xhtml={researchStudySummary} /><br /><br /></div>}
      {researchStudyReference && <div><br /><b>Reference to {researchStudyReference?.type && researchStudyReference.type + " "} Resource: </b><DisplayFromFHIR reference={researchStudyReference} /></div>}
      {sectionAuthor && sectionAuthor}
    </>
      :
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={researchStudySection.emptyReason} />
      </>}
  </div>
}

const ResultsSectionDisplay = ({ resultsSection, resourceDictionary }) => {
  let resultCompositionJson = getJsonFromIdentifier(resourceDictionary, resultsSection.entry, "Composition");
  let resultsSummary;
  if (resultsSection.text?.status && resultsSection.text.status !== "empty") {
    resultsSummary = resultsSection.text.div;
  } else if (resultCompositionJson?.text?.status && resultCompositionJson.text.status !== "empty") {
    resultsSummary = resultCompositionJson.text.div;
  }
  let resultsReference;
  if (resultsSection.entry?.length > 0) {
    resultsReference = resultsSection.entry[0];
  }
  let sectionAuthor;
  if (Array.isArray(resultsSection.author) && resultsSection.author.length > 0) {
    sectionAuthor = resultsSection.author.map((author, authorIndex) => {
      return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
    })
  }

  return <div>
    {(resultsSummary || resultsReference) ? <>
      {resultsSummary && <div><br /><DisplayFromFHIR xhtml={resultsSummary} /><br /><br /></div>}
      {resultsReference && <div><br />
      <b>Reference to {resultsReference?.type && resultsReference.type + " "}Resource: </b>
      <DisplayFromFHIR reference={resultsReference} />
      </div>}
      {sectionAuthor && sectionAuthor}
      {resultsSection.section?.[0] && <div>
        <div>
          <p><b>Findings for Population Subgroups:</b></p>
          <div style={{ marginLeft: "24px" }}>
            {(resultsSection.section[0].text && Object.keys(resultsSection.section[0].text).length > 0) && <div>
              {resultsSection.section[0].text.status === "empty" ?
                <></>
                :
                <div>
                  <b>Narrative Summary: </b>
                  <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                    <DisplayFromFHIR xhtml={resultsSection.section[0].text.div} />
                  </div>
                </div>}
              <br />
            </div>}
            {resultsSection.section[0].orderedBy && <span>
              <b>Ordered by: </b>
              <DisplayFromFHIR codeableConcept={resultsSection.section[0].orderedBy} /><br />
            </span>}
            {(Array.isArray(resultsSection.section[0].entry) && resultsSection.section[0].entry.length > 0) &&
              resultsSection.section[0].entry.map((entry, entryIndex) => {
                return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
              })}
            {resultsSection.section[0].emptyReason && <span>
              <b>Empty Reason: </b>
              <DisplayFromFHIR codeableConcept={resultsSection.section[0].emptyReason} /><br />
            </span>}
          </div>
        </div>
      </div>}
    </>
      :
      <><b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={resultsSection.emptyReason} />
      </>}
  </div>
}

const ComparativeEvidenceSynthesisReportViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }
  const globalContext = useContext(FevirContext);
  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(fhirJson, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, []);

  let populationSection = {};
  let interventionSection = {};
  let comparatorSection = {};
  let researchStudySection = {};
  let resultsSection = {};
  let summaryOfFindingsSection = {};
  let introductionSection = {};
  let discussionSection = {};
  let methodsSection = {};
  let referencesSection = {};
  let competingInterestsSection = {};
  let acknowlegementsSection = {};
  let appendicesSection = {};
  let additionalSections = [];

  if (Array.isArray(fhirJson.section)) {
    for (const section of fhirJson.section) {
      let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
      if (sectionCode === "population" || sectionCode === "Population") {
        populationSection = section;
      } else if (sectionCode === "intervention-description" || sectionCode === "Intervention Description" || sectionCode === "intervention" || sectionCode === "Intervention") {
        interventionSection = section;
      } else if (sectionCode === "comparator-description" || sectionCode === "Comparator Description" || sectionCode === "comparator" || sectionCode === "Comparator") {
        comparatorSection = section;
      } else if (sectionCode === "research-study" || sectionCode === "Research Study") {
        researchStudySection = section;
      } else if (sectionCode === "results" || sectionCode === "Results") {
        resultsSection = section;
      } else if (sectionCode === "summary-of-findings" || sectionCode === "Summary of Findings") {
        summaryOfFindingsSection = section;
      } else if (sectionCode === "introduction" || sectionCode === "Introduction") {
        introductionSection = section;
      } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
        discussionSection = section;
      } else if (sectionCode === "methods" || sectionCode === "Methods") {
        methodsSection = section;
      } else if (sectionCode === "references" || sectionCode === "References") {
        referencesSection = section;
      } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
        competingInterestsSection = section;
      } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
        acknowlegementsSection = section;
      } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
        appendicesSection = section;
      } else {
        additionalSections.push(section);
      }
    }
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="introduction">Introduction</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Comparative Evidence Synthesis Report Title: </b>
          {fhirJson.title ? fhirJson.title : fhirJson.name ? fhirJson.name : "No title provided."}
        </p>
        {description &&
          <div><b>Comparative Evidence Synthesis Report Description: </b><DisplayFromFHIR markdown={description} />
          </div>}
        {Array.isArray(fhirJson.subject) &&
          fhirJson.subject.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Subject: </b><DisplayFromFHIR reference={subject} /></div>;
          })}
        {Array.isArray(fhirJson.category) &&
          fhirJson.category.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={subject} /></div>;
          })}
        <EvidenceReportPackageSectionDisplay topSection={introductionSection} />
      </div>
      <h3 id="population">Population</h3>
      <div style={{ marginLeft: "24px" }}>
        <PopulationSectionDisplay populationSection={populationSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="intervention">Intervention</h3>
      <div style={{ marginLeft: "24px" }}>
        <PopulationSectionDisplay populationSection={interventionSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="comparator">Comparator</h3>
      <div style={{ marginLeft: "24px" }}>
        <PopulationSectionDisplay populationSection={comparatorSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="research-study">Research Study</h3>
      <div style={{ marginLeft: "24px" }}>
        <ResearchStudySectionDisplay researchStudySection={researchStudySection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="methods">Methods</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={methodsSection} />
      </div>
      <h3 id="results">Results</h3>
      <div style={{ marginLeft: "24px" }}>
        <ResultsSectionDisplay resultsSection={resultsSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="summary-of-findings">Summary of Findings</h3>
      <div style={{ marginLeft: "24px" }}>
        <ResultsSectionDisplay resultsSection={summaryOfFindingsSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="discussion">Discussion</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={discussionSection} />
        {additionalSections?.length > 0 && additionalSections.map((section, sectionIndex) => {
          return <div key={sectionIndex} >
            <EvidenceReportPackageSectionDisplay topSection={section} />
          </div>
        })}
      </div>
      <h3 id="references">References</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={referencesSection} />
      </div>
      <h3 id="competing-interests">Competing Interests</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={competingInterestsSection} />
      </div>
      <h3 id="acknowledgements">Acknowledgements</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={acknowlegementsSection} />
      </div>
      <h3 id="appendices">Appendices</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={appendicesSection} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(sourceJsonState.howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={sourceJsonState.howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(fhirJson.category) && fhirJson.category.length > 0) && <>
          {fhirJson.category.map((category, categoryIndex) => {
            return <span key={categoryIndex} >
              <b>Category: </b>
              <DisplayFromFHIR codeableConcept={category} />
            </span>
          })}
          <br /> <br />
        </>}
        {(Array.isArray(fhirJson.subject) && fhirJson.subject.length > 0) && <>
          {fhirJson.subject.map((subject, subjectIndex) => {
            return <span key={subjectIndex} >
              <b>Subject: </b>
              <DisplayFromFHIR reference={subject} />
            </span>
          })}
          <br /> <br />
        </>}
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ComparativeEvidenceSynthesisReportViewerTool;