import React, { useState, useEffect, memo } from 'react';
import { TextField } from '@mui/material';
import { Button, Dimmer, Loader, Table, Modal } from 'semantic-ui-react';
import { compositionTypeCodeSummaryOfFindings, emptyReasonNotStarted, emptyReasonValueSet, emptyTextNoData, sectionCodeAssertion, sectionCodeCertaintyOfEvidence, sectionCodeColumnHeaders, sectionCodeComparatorGroup, sectionCodeGroupAssignment, sectionCodeGroups, sectionCodeInterventionGroup, sectionCodeOutcomeMeasure, sectionCodePopulation, sectionCodeRelativeImportance, sectionCodeResultWithComparatorAlone, sectionCodeResultWithInterventionAlone, sectionCodeResultWithInterventionAloneCalculated, sectionCodeResultWithInterventionVsComparator, sectionCodeSampleSize, sectionCodeSummaryOfFindings, sectionCodeSummaryOfFindingsForSingleOutcome, sectionCodeVariables } from './CodeSystemLookup';
import { submitUpdatedFhirResource, DisplayFromFHIR, clearDivWrapper } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import createCompositionTableDict from './createCompositionTableDict';
import SummaryOfFindingsSubsectionDataEntry from './SummaryOfFindingsSubsectionDataEntry';
import submitToFevirServer from './SubmitToFevirServer';
import { addFoisFromFlis, getTargetResourceRatingDictionary, submitResource } from './SummaryOfFindingsFunctions';
import RateRelativeImportance from './RateRelativeImportance';
import { loadSourceJsonWithRatingDictionaryFunction, identifyBaselineRiskResources } from './loadSourceJsonFunction';
import { autoEditEmptyReason } from './CompositionDocumentBundleFunctions';

const calculateInterventionResultFromControlEventRateAndStatistic = (controlEventRate, statistic) => {
  let interventionResult = NaN;
  let riskDifference = NaN;
  if (statistic) {
    for (const stat of statistic) {
      if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
        codeMatchesRiskDifference(stat.statisticType.coding[0].code)) {
        if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
          riskDifference = stat.quantity.value;
        }
      }
      if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
        codeMatchesRelativeRisk(stat.statisticType.coding[0].code)) {
        if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
          let relativeRisk = stat.quantity.value;
          let interventionEventRate = controlEventRate * relativeRisk;
          riskDifference = interventionEventRate - controlEventRate;
          break;
        }
      }
      if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
        stat.statisticType.coding[0].code === "STATO:0000182") {
        if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
          let oddsRatio = stat.quantity.value;
          let controlOdds = controlEventRate / (1 - controlEventRate);
          let interventionOdds = controlOdds * oddsRatio;
          let interventionEventRate = interventionOdds / (interventionOdds + 1);
          riskDifference = interventionEventRate - controlEventRate;
          break;
        }
      }
    }
    interventionResult = (controlEventRate + riskDifference) * 100;
    if (isNaN(riskDifference) && !isNaN(controlEventRate)) {
      for (const stat of statistic) {
        if (stat.statisticType?.coding?.length > 0 && stat.statisticType.coding[0].code) {
          const statCode = stat.statisticType.coding[0].code;
          if (checkIfStatisticAbsoluteOrRelative(statCode) === "absolute") {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              let absoluteDifference = stat.quantity.value;
              interventionResult = controlEventRate + absoluteDifference;
              break;
            }
          } else if (checkIfStatisticAbsoluteOrRelative(statCode) === "relative") {
            if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
              let relativeDifference = stat.quantity.value;
              interventionResult = controlEventRate * relativeDifference;
              break;
            }
          }
        }
      }
    }
  }
  return interventionResult;
};

const checkIfStatisticAbsoluteOrRelative = (statisticCode) => {
  const absoluteList = ["C44256", "STATO:0000607", "STATO:0000424", "0000424", "C16726", "C17010", "C53319", "0000100", "0000457", "C28007", "C25463", "C25564", "C25570", "absolute-MedianDiff", "predictedRisk"];
  const relativeList = ['C93150', 'STATO:0000245', 'STATO:0000182', 'TBD:0000026', 'TBD:0000028', 'TBD:0000027', "C93152", "C93150", "C16932", "rate-ratio", 'STATO:0000245', 'STATO:0000182', 'TBD:0000026', 'TBD:0000028', 'TBD:0000027'];
  if (absoluteList.includes(statisticCode)) {
    return "absolute";
  } else if (relativeList.includes(statisticCode)) {
    return "relative";
  } else {
    return "Not recognized";
  }
};

const codeMatchesPercentage = (code) => {
  return (code === 'STATO:0000639' || code === 'TBD:0000017');
};

const codeMatchesProportion = (code) => {
  return (code === "STATO:0000607" || code === "C44256" || code === "TBD:0000185" || code === 'TBD:0000018' || code === 'STATO:0000620');
};

const codeMatchesRelativeRisk = (code) => {
  return (code === 'STATO:0000245' || code === 'C93152' || code === 'C93150');
};

const codeMatchesRiskDifference = (code) => {
  return (code === 'STATO:0000424' || code === "0000424");
};

const extractControlEventRateFromStatistic = (statistic) => {
  let controlEventRate = NaN;
  for (const stat of statistic) {
    if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
      codeMatchesProportion(stat.statisticType.coding[0].code)) {
      if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
        controlEventRate = stat.quantity.value;
        break;
      }
    }
    if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
      codeMatchesPercentage(stat.statisticType.coding[0].code)) {
      if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
        controlEventRate = stat.quantity.value / 100;
        break;
      }
    }
    if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
      (stat.statisticType.coding[0].code === 'STATO:0000047')) {
      if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value) && stat.sampleSize) {
        let denominator = stat.sampleSize.knownDataCount || stat.sampleSize.numberOfParticipants || 0;
        if (denominator > 0) {
          controlEventRate = (stat.quantity.value / denominator);
          break;
        }
      }
    }
  }
  return controlEventRate;
};

const generateSectionTextForAssertion = (section, comparativeEvidenceJson, sourceReferences) => {
  if (comparativeEvidenceJson) {
    section.entry = [sourceReferences.comparativeEvidence];
    if (comparativeEvidenceJson.assertion) {
      section.text = {
        "status": "generated",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + comparativeEvidenceJson.assertion + "</p></div>"
      };
    } else {
      section.text = {
        "status": "empty",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No assertion provided.]</p></div>"
      }
    }
  }
};

const generateSectionTextFromCalculatedInterventionResult = (section, interventionResult) => {
  if (isNaN(interventionResult)) {
    section.text = {
      "status": "generated",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[Not a proportion.]</p></div>"
    };
  } else {
    section.text = {
      "status": "generated",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + parseFloat(interventionResult.toFixed(2)) + "%</p></div>"
    };
  }
};

const generateSectionTextForCalculatedInterventionResult = (section, comparativeEvidenceJson,
  comparatorOnlyEvidenceJson, sourceReferences) => {
  if (comparativeEvidenceJson && comparatorOnlyEvidenceJson) {
    section.entry = [sourceReferences.comparatorOnlyEvidence, sourceReferences.comparativeEvidence];
    let controlEventRate = NaN;
    let interventionResult = NaN;
    if (comparatorOnlyEvidenceJson.statistic) {
      controlEventRate = extractControlEventRateFromStatistic(comparatorOnlyEvidenceJson.statistic);
    }
    if (comparativeEvidenceJson.statistic) {
      interventionResult = calculateInterventionResultFromControlEventRateAndStatistic(controlEventRate, comparativeEvidenceJson.statistic);
    }
    generateSectionTextFromCalculatedInterventionResult(section, interventionResult);
  }
};

const generateSectionTextForCertaintyOfEvidence = (section, comparativeEvidenceJson, sourceReferences) => {
  if (comparativeEvidenceJson) {
    section.entry = [sourceReferences.comparativeEvidence];
    if (comparativeEvidenceJson.certainty?.length > 0) {
      section.text = {
        "status": "empty",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No Overall certainty rating found.]</p></div>"
      };
      for (const certainty of comparativeEvidenceJson.certainty) {
        if (certainty.type && certainty.type.coding && certainty.type.coding.length > 0 && certainty.type.coding[0].code == "Overall") {
          if (certainty.rating && certainty.rating.coding && certainty.rating.coding.length > 0 && certainty.rating.coding[0].display) {
            section.text = {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + certainty.rating.coding[0].display + "</p></div>"
            };
          }
        }
      }
    } else {
      section.text = {
        "status": "empty",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No certainty rating found.]</p></div>"
      }
    }
  }
};

const generateSectionTextForEffectEstimate = (section, comparativeEvidenceJson, sourceReferences) => {
  if (comparativeEvidenceJson) {
    section.entry = [sourceReferences.comparativeEvidence];
    if (comparativeEvidenceJson.statistic?.length > 0) {
      let statisticNarrativeSummary = "";
      for (const statistic of comparativeEvidenceJson.statistic) {
        let statisticTypeDisplay = '';
        let statisticValueString = '';
        let statisticValueNumber;
        let attributeEstimateDisplay = '';
        let lowValue = 'NA';
        let highValue = 'NA';
        let lowValueNumber;
        let highValueNumber;
        let isARiskDifference = false;
        if (statistic.statisticType && statistic.statisticType.coding && statistic.statisticType.coding.length > 0 && statistic.statisticType.coding[0].display) {
          statisticTypeDisplay = statistic.statisticType.coding[0].display + ' ';
          if (codeMatchesRiskDifference(statistic.statisticType.coding[0].code)) {
            isARiskDifference = true;
          }
        }
        if (statistic.quantity && statistic.quantity.value !== undefined && statistic.quantity.value !== null && !isNaN(statistic.quantity.value)) {
          statisticValueNumber = Number(statistic.quantity.value);
          if (statistic.quantity.unit !== undefined && statistic.quantity.unit !== "") {
            statisticValueString = parseFloat(statisticValueNumber.toFixed(2)) + " " + statistic.quantity.unit;
          } else if (isARiskDifference) {
            statisticValueString = parseFloat((statisticValueNumber * 100).toFixed(2)) + "%";
          } else {
            statisticValueString = parseFloat(statisticValueNumber.toFixed(2)).toString();
          }
        }
        if (statistic.attributeEstimate && statistic.attributeEstimate.length > 0) {
          const attributeEstimate = statistic.attributeEstimate[0];
          let attributeEstimateType = '';
          if (attributeEstimate.type && attributeEstimate.type.coding && attributeEstimate.type.coding.length > 0 && attributeEstimate.type.coding[0].display) {
            attributeEstimateType = '95% ' + attributeEstimate.type.coding[0].display;
          }
          if (attributeEstimate.range) {
            if (attributeEstimate.range.low && attributeEstimate.range.low.value !== undefined && !isNaN(attributeEstimate.range.low.value)) {
              lowValueNumber = Number(attributeEstimate.range.low.value);
              if (attributeEstimate.range.low.unit !== undefined && attributeEstimate.range.low.unit !== "") {
                lowValue = parseFloat(lowValueNumber.toFixed(2)) + " " + attributeEstimate.range.low.unit;
              } else if (isARiskDifference) {
                lowValue = parseFloat((lowValueNumber * 100).toFixed(2)) + "%";
              } else {
                lowValue = parseFloat(lowValueNumber.toFixed(2)).toString();
              }
            }
            if (attributeEstimate.range.high && attributeEstimate.range.high.value !== undefined && !isNaN(attributeEstimate.range.high.value)) {
              highValueNumber = Number(attributeEstimate.range.high.value);
              if (attributeEstimate.range.high.unit !== undefined && attributeEstimate.range.high.unit !== "") {
                highValue = parseFloat(highValueNumber.toFixed(2)) + " " + attributeEstimate.range.high.unit;
              } else if (isARiskDifference) {
                highValue = parseFloat((highValueNumber * 100).toFixed(2)) + "%";
              } else {
                highValue = parseFloat(highValueNumber.toFixed(2)).toString();
              }
            }
          }
          attributeEstimateDisplay = ' (' + attributeEstimateType + ' ' + lowValue + ' to ' + highValue + ')'
        }
        if (statisticNarrativeSummary) {
          statisticNarrativeSummary += "; " + statisticTypeDisplay + statisticValueString + attributeEstimateDisplay
        } else {
          statisticNarrativeSummary = statisticTypeDisplay + statisticValueString + attributeEstimateDisplay
        }
      }
      section.text = {
        "status": "generated",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + statisticNarrativeSummary + "</p></div>"
      };
    } else {
      section.text = {
        "status": "empty",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No statistic found.]</p></div>"
      }
    }
  }
};

const generateSectionTextForPercentage = (section, statistic) => {
  if (statistic?.length > 0) {
    let percentage = NaN;
    for (const stat of statistic) {
      if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
        codeMatchesProportion(stat.statisticType.coding[0].code)) {
        if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
          percentage = stat.quantity.value * 100;
          break;
        }
      }
      if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
        codeMatchesPercentage(stat.statisticType.coding[0].code)) {
        if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value)) {
          percentage = stat.quantity.value;
          break;
        }
      }
      if (stat.statisticType && stat.statisticType.coding && stat.statisticType.coding.length > 0 &&
        (stat.statisticType.coding[0].code === 'STATO:0000047')) {
        if (stat.quantity && stat.quantity.value !== undefined && !isNaN(stat.quantity.value) && stat.sampleSize) {
          let denominator = stat.sampleSize.knownDataCount || stat.sampleSize.numberOfParticipants || 0;
          if (denominator > 0) {
            percentage = (stat.quantity.value / denominator) * 100;
            break;
          }
        }
      }
    }
    if (isNaN(percentage)) {
      section.text = {
        "status": "empty",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[Not a proportion.]</p></div>"
      }
    } else {
      section.text = {
        "status": "generated",
        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + parseFloat(percentage.toFixed(2)) + "%</p></div>"
      }
    }
  } else {
    section.text = {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No statistic found.]</p></div>"
    }
  }
};

const generateSectionTextForRelativeImportance = (section, outcomeImportanceJson, sourceReferences) => {
  if (outcomeImportanceJson) {
    section.entry = [sourceReferences.outcomeImportance];
    section.text = {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No relative outcome importance rating found.]</p></div>"
    }
    if (outcomeImportanceJson.content?.length > 0) {
      let sectionTextDiv = "";
      let sectionTextDivDesirability = "";
      let sectionTextDivRelativeImportance = "";

      for (const content of outcomeImportanceJson.content) {
        if (content.type && content.type.coding && content.type.coding.length > 0 && content.type.coding[0].code) {
          if (content.type.coding[0].code === "desirability") {
            if (content.classifier?.length > 0 && content.classifier[0].coding?.length > 0 &&
              content.classifier[0].coding[0]) {
              sectionTextDivDesirability = content.classifier[0].coding[0].display || content.classifier[0].coding[0].code;
            }
          }
          if (content.type.coding[0].code === "relative-importance") {
            if (content.quantity?.value >= 0) {
              sectionTextDivRelativeImportance = content.quantity.value.toString() + "%";
            }
          }
        }
      }
      if (sectionTextDivRelativeImportance) {
        sectionTextDiv = sectionTextDivDesirability + " " + sectionTextDivRelativeImportance;
      }
      if (sectionTextDiv) {
        section.text = {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + sectionTextDiv + "</p></div>"
        };
      }
    }
  }
};

const generateSectionTextForSampleSize = (section, comparativeEvidenceJson, sourceReferences) => {
  if (comparativeEvidenceJson) {
    section.entry = [sourceReferences.comparativeEvidence];
    section.text = {
      "status": "empty",
      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No statistic found.]</p></div>"
    }
    if (comparativeEvidenceJson.statistic?.length > 0) {
      for (const statistic of comparativeEvidenceJson.statistic) {
        let resultDisplayList = [];
        if (statistic.sampleSize) {
          if (statistic.sampleSize.numberOfStudies !== undefined && statistic.sampleSize.numberOfStudies !== "") {
            resultDisplayList.push(statistic.sampleSize.numberOfStudies + ' studies');
          }
          if (statistic.sampleSize.numberOfParticipants !== undefined && statistic.sampleSize.numberOfParticipants !== "") {
            resultDisplayList.push(statistic.sampleSize.numberOfParticipants + ' participants');
          }
          if (statistic.sampleSize.knownDataCount !== undefined && statistic.sampleSize.knownDataCount !== "") {
            resultDisplayList.push(statistic.sampleSize.knownDataCount + ' counted');
          }
        }
        if ((statistic.numberAffected !== undefined && statistic.numberAffected !== "") || (statistic.numberOfEvents !== undefined && statistic.numberOfEvents !== "")) {
          let abc = statistic.numberAffected !== undefined ? statistic.numberAffected : statistic.numberOfEvents;
          resultDisplayList.push(abc + ' events');
        }
        if (resultDisplayList.length) {
          section.text = {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + resultDisplayList.join(', ') + "</p></div>"
          };
          break;
        } else {
          section.text = {
            "status": "empty",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No sample size data.]</p></div>"
          };
        }

      }
    }
  }
};

//--ChangeBaselineRiskEstimates--

const getTargetBaselineRiskResourceDictionary = async (targetResources, baselineRiskResources, globalContext) => {
  let getBody = {
    'functionid': 'gettargetbaselineriskresourcedictionary',
    'idToken': '',
    'targetResources': targetResources,
    'targetComparatorOnlyEvidenceResources': baselineRiskResources,
    'userid': globalContext.userState.id,
    'tool': 'SummaryOfFindingsAuthoringTool'
  };
  let response = await submitToFevirServer(globalContext, 50000, getBody, false, false);
  return response;
};

const updateResourceWithModifiedRisks = (resource, modifiedRisk) => {
  resource.statistic = resource.statistic.map(statistic => {
    if (statistic.statisticType?.coding?.length > 0 &&
      codeMatchesProportion(statistic.statisticType.coding[0].code)) {
      statistic.quantity.value = modifiedRisk;
    }
    return statistic;
  })
  return resource;
};

const ModifiedRiskEntry = memo(({ fieldLabel, targetfoi, startingValue,
  setChangeBaselineRatesWorkState, setChangeAvailableToSaveState }) => {

  return <div>
    <TextField style={{ width: "100%", marginTop: "16px", maxWidth: "150px" }}
      className="inputField" type='number' label={fieldLabel} size="small" variant='outlined'
      value={startingValue}
      onChange={(e) => {
        setChangeBaselineRatesWorkState(prevState => {
          let newDictionary = JSON.parse(JSON.stringify(prevState));
          newDictionary[targetfoi]["modifiedRisk"] = parseFloat(e.target.value) / 100;
          newDictionary[targetfoi]["itemChanged"] = true;
          return newDictionary;
        });
        setChangeAvailableToSaveState(true);
      }} />
    <>%</>
  </div>
});

const ChangeBaselineRatesTable = ({ changeBaselineRatesWorkState, setChangeBaselineRatesWorkState,
  targetResources, setChangeAvailableToSaveState, globalContext, dataLoadingState }) => {

  const [ready, setReady] = useState(false);

  const addFoisFromFlisInTargetResources = async () => {
    await addFoisFromFlis(globalContext, targetResources);
    setReady(true);
  }

  useEffect(() => {
    addFoisFromFlisInTargetResources();
  }, []);

  return <>
    {dataLoadingState ?
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      :
      <>
        {ready && <Table className='viewmyworktable'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Outcome
              </Table.HeaderCell>
              <Table.HeaderCell>
                Baseline Risk (as %)
              </Table.HeaderCell>
              <Table.HeaderCell>
                Change Baseline Risk (as %)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(targetResources?.length > 0 && Object.keys(changeBaselineRatesWorkState).length > 0) &&
              targetResources.map((item, itemIndex) => {
                return <Table.Row key={itemIndex}>
                  <Table.Cell>
                    <a href={"/resources/EvidenceVariable/" + item.id.toString()}
                      target="_blank" rel="noopener noreferrer" >
                      {item.title.replace("OutcomeDefinition: ", "").replace("OutcomeVariable: ", "")}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    {parseFloat((changeBaselineRatesWorkState[item.id].defaultRisk * 100).toFixed(2))}%
                  </Table.Cell>
                  <Table.Cell>
                    <ModifiedRiskEntry targetfoi={item.id}
                      setChangeBaselineRatesWorkState={setChangeBaselineRatesWorkState}
                      fieldLabel={'Risk without treatment (as %)'}
                      startingValue={parseFloat((changeBaselineRatesWorkState[item.id]["modifiedRisk"] * 100).toFixed(2)).toString()}
                      setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                  </Table.Cell>
                </Table.Row>
              })}
          </Table.Body>
        </Table>}
      </>}
  </>
};

const ChangeBaselineRiskEstimatesModal = memo(({ globalContext, resourceState, setResourceState,
  changeBaselineRiskEstimatesModalState, setChangeBaselineRiskEstimatesModalState, setNeedToRefresh2State }) => {

  const targetResources = resourceState.targetResources;

  const baselineRiskResources = resourceState.baselineRiskResources;

  const [changeBaselineRatesWorkState, setChangeBaselineRatesWorkState] = useState({});
  const [saveChangesState, setSaveChangesState] = useState(false);
  const [changeAvailableToSaveState, setChangeAvailableToSaveState] = useState(false);
  const [dataLoadingState, setDataLoadingState] = useState(false);

  const loadChangeBaselineRatesWork = async () => {
    setDataLoadingState(true);
    let response = await getTargetBaselineRiskResourceDictionary(targetResources, baselineRiskResources, globalContext);
    if (response?.success && response.targetBaselineRiskResourceDictionary) {
      setChangeBaselineRatesWorkState(response.targetBaselineRiskResourceDictionary);
      setDataLoadingState(false);
    } else {
      alert("Problem with getTargetBaselineRiskResourceDictionary");
      setDataLoadingState(false);
    }
  }

  useEffect(() => {
    loadChangeBaselineRatesWork();
  }, []);

  const saveChanges = async () => {
    let newSection = JSON.parse(JSON.stringify(resourceState.section));
    let findingsSection = newSection[1].section;
    for (const key in changeBaselineRatesWorkState) {
      const item = changeBaselineRatesWorkState[key];
      if (item.itemChanged) {
        if (!item.exists) {
          let newComparatorOnlyEvidence = {
            "resourceType": "Evidence",
            "meta": {
              "profile": [
                "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparator-only-evidence"
              ]
            },
            "title": "Baseline risk of " + item.targettitleTrimmed + " for " + globalContext.userState.name,
            "status": "active",
            "author": [
              {
                "name": globalContext.userState.name
              }
            ],
            "variableDefinition": [
              {
                "description": globalContext.userState.name,
                "variableRole": "population",
                "intended": {
                  "display": "selected by " + globalContext.userState.name
                }
              },
              {
                "description": item.targettitleTrimmed,
                "variableRole": "outcome",
                "observed": {
                  "reference": "EvidenceVariable/" + key,
                  "type": "EvidenceVariable",
                  "display": item.targettitle
                }
              }
            ],
            "statistic": [
              {
                "statisticType": {
                  "coding": [
                    {
                      "system": "https://fevir.net/resources/CodeSystem/27270",
                      "code": "STATO:0000607",
                      "display": "Proportion"
                    }
                  ]
                },
                "quantity": {
                  "value": item.modifiedRisk
                }
              }
            ]
          }
          let newEvidenceBody = {
            'functionid': 'submitfhirresource',
            'idToken': "",
            'fhirEntry': JSON.stringify(newComparatorOnlyEvidence),
            'status': "active",
            'aboutformstateid': key,
            'tool': 'SummaryOfFindingsAuthoringTool',
            'title': newComparatorOnlyEvidence.title
          }
          let response = await submitToFevirServer(globalContext, 120000, newEvidenceBody, false, false);
          if (response?.success) {
            let newFOI = response.formstateid;
            for (let section of findingsSection) {
              if (section.focus?.reference === "EvidenceVariable/" + key) {
                for (let subsection of section.section) {
                  if (subsection.code?.coding?.length > 0 &&
                    (subsection.code.coding[0].code === "evidence-with-comparator-alone" || subsection.code.coding[0].code === "result-with-comparator-alone" || subsection.code.coding[0].code === "Control-group-alone-Evidence")) {
                    subsection.text.status = "additional";
                    subsection.text.div = "baseline risk of: " + (item.modifiedRisk * 100) + "%";
                    subsection.entry = [{
                      "reference": "Evidence/" + newFOI,
                      "type": "Evidence",
                      "display": newComparatorOnlyEvidence.title
                    }]
                  }
                  if (subsection.code?.text === "result with intervention with calculated value derived from the control group and effect estimate" ||
                    (subsection.code?.coding?.length > 0 &&
                      subsection.code.coding[0].code === "result-with-intervention-alone-calculated")) {
                    if (subsection.entry) {
                      subsection.entry[0] = {
                        "reference": "Evidence/" + newFOI,
                        "type": "Evidence",
                        "display": newComparatorOnlyEvidence.title
                      };
                    } else {
                      subsection.entry = [{
                        "reference": "Evidence/" + newFOI,
                        "type": "Evidence",
                        "display": newComparatorOnlyEvidence.title
                      }];
                    }

                    let controlEventRate = item.modifiedRisk;
                    let comparativeEvidenceFOI = subsection.entry[1]?.reference?.replace("Evidence/", "");
                    let comparativeEvidenceJson;
                    if (comparativeEvidenceFOI) {
                      const body = {
                        'functionid': 'getfhirresource',
                        'resourceid': comparativeEvidenceFOI,
                        'resourcetype': "Evidence",
                        'idToken': ""
                      };
                      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
                      if (response.success && response.fhirjsonstring) {
                        comparativeEvidenceJson = JSON.parse(response.fhirjsonstring);
                      }
                    }
                    let interventionResult = NaN;
                    if (comparativeEvidenceJson?.statistic) {
                      interventionResult = calculateInterventionResultFromControlEventRateAndStatistic(controlEventRate, comparativeEvidenceJson.statistic);
                    }
                    generateSectionTextFromCalculatedInterventionResult(subsection, interventionResult);
                  }
                }
              }
            }
          } else {
            alert("Problem submitting ComparatorOnlyEvidence for FOI " + key);
          }
          setChangeBaselineRatesWorkState(prevState => {
            let newDictionary = JSON.parse(JSON.stringify(prevState));
            newDictionary[key]["exists"] = true;
            newDictionary[key]["itemChanged"] = false;
            return newDictionary;
          });
        }
        if (item.exists) {
          let updatedResource = updateResourceWithModifiedRisks(item.resource, item.modifiedRisk);
          let updatedRiskBody = {
            'functionid': 'updatefhirresource',
            'idToken': "",
            'resourceid': item.resourceFOI,
            'resourcetype': "Evidence",
            'fhirEntry': JSON.stringify(updatedResource),
            'status': "active",
            'aboutformstateid': key,
            'tool': 'SummaryOfFindingsAuthoringTool',
            'title': updatedResource.title
          }
          let response = await submitToFevirServer(globalContext, 120000, updatedRiskBody, false, false);
          if (response?.success) {
            let newSection = JSON.parse(JSON.stringify(resourceState.section));
            for (let section of newSection) {
              if (section.focus?.reference === "EvidenceVariable/" + key) {
                for (let subsection of section.section) {
                  if (subsection.code?.coding?.length > 0 &&
                    (subsection.code.coding[0].code === "evidence-with-comparator-alone" || subsection.code.coding[0].code === "result-with-comparator-alone" || subsection.code.coding[0].code === "Control-group-alone-Evidence")) {
                    subsection.text.status = "additional";
                    subsection.text.div = "baseline risk of: " + (item.modifiedRisk * 100) + "%";
                    subsection.entry = [{
                      "reference": "Evidence/" + item.resourceFOI,
                      "type": "Evidence",
                      "display": updatedResource.title
                    }]
                  }
                  if (subsection.code?.text === "result with intervention with calculated value derived from the control group and effect estimate" ||
                    (subsection.code?.coding?.length > 0 &&
                      subsection.code.coding[0].code === "result-with-intervention-alone-calculated")) {
                    subsection.entry[0] = {
                      "reference": "Evidence/" + item.resourceFOI,
                      "type": "Evidence",
                      "display": updatedResource.title
                    };
                    let controlEventRate = item.modifiedRisk;
                    let comparativeEvidenceFOI = subsection.entry[1].reference.replace("Evidence/", "");
                    let comparativeEvidenceJson;
                    const body = {
                      'functionid': 'getfhirresource',
                      'resourceid': comparativeEvidenceFOI,
                      'resourcetype': "Evidence",
                      'idToken': ""
                    };
                    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
                    if (response.success && response.fhirjsonstring) {
                      comparativeEvidenceJson = JSON.parse(response.fhirjsonstring);
                    }
                    let interventionResult = NaN;
                    if (comparativeEvidenceJson.statistic) {
                      interventionResult = calculateInterventionResultFromControlEventRateAndStatistic(controlEventRate, comparativeEvidenceJson.statistic);
                    }
                    generateSectionTextFromCalculatedInterventionResult(subsection, interventionResult);
                  }
                }
              }
            }
          } else {
            alert("Problem updating ComparatorOnlyEvidence for FOI " + key);
          }
          setChangeBaselineRatesWorkState(prevState => {
            let newDictionary = JSON.parse(JSON.stringify(prevState));
            newDictionary[key]["exists"] = true;
            newDictionary[key]["itemChanged"] = false;
            return newDictionary;
          });
        }
      }
    }
    newSection[1].section = findingsSection;
    setResourceState(prevState => {
      return { ...prevState, "section": newSection };
    });
    await loadChangeBaselineRatesWork();
    setSaveChangesState(false);
    setChangeAvailableToSaveState(false);
    setNeedToRefresh2State(true);
    alert("Your changes are saved. Please wait a moment to confirm the changes appear in the Table View.");
    //close window added to save changes because re-editing after save was reverting to earlier value on first data entry
    setChangeBaselineRiskEstimatesModalState(prevState => { return { ...prevState, modalOpen: false }; });
  }

  let modalContent = <div style={{
    paddingTop: "0px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <span style={{
      position: "absolute", backgroundColor: "#FFFFFF", width: "calc(90vw - 38px)", zIndex: 100,
      paddingTop: "6px", paddingBottom: "6px"
    }} >
      <Button style={{ color: changeAvailableToSaveState ? "#FFFFFF" : "#000000", width: "230px", float: "left" }}
        className="formButton" disabled={saveChangesState}
        content="Save Changes" positive={changeAvailableToSaveState}
        onClick={() => {
          setSaveChangesState(true);
          saveChanges();
        }}
      />
      &nbsp;&nbsp;&nbsp;
      <Button style={{ color: "#000000", width: "180px", float: "right" }} className="formButton"
        content="Close Window"
        onClick={() => {
          setChangeBaselineRiskEstimatesModalState(prevState => { return { ...prevState, modalOpen: false }; });
        }}
      />
    </span>
    <br /><br /><br />
    <div className='tableFixHead'>
      <ChangeBaselineRatesTable targetResources={targetResources} globalContext={globalContext}
        dataLoadingState={dataLoadingState}
        changeBaselineRatesWorkState={changeBaselineRatesWorkState} setChangeBaselineRatesWorkState={setChangeBaselineRatesWorkState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
    <br /><br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={changeBaselineRiskEstimatesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      className="viewmywork"
    />
  )
});

const ChangeBaselineRiskEstimates = memo(({ globalContext, resourceState, setResourceState,
  previousVersionLoaded, setNeedToRefresh2State }) => {

  const [changeBaselineRiskEstimatesModalState, setChangeBaselineRiskEstimatesModalState] = useState({ "modalOpen": false });

  return <>
    {changeBaselineRiskEstimatesModalState.modalOpen &&
      <ChangeBaselineRiskEstimatesModal globalContext={globalContext} resourceState={resourceState}
        setResourceState={setResourceState}
        changeBaselineRiskEstimatesModalState={changeBaselineRiskEstimatesModalState}
        setNeedToRefresh2State={setNeedToRefresh2State}
        setChangeBaselineRiskEstimatesModalState={setChangeBaselineRiskEstimatesModalState} />}
    <span>
      <Button className="formButton" style={{ color: "#000000", width: "220px" }}
        content="Change Baseline Risk Estimates"
        onClick={() => {
          if (globalContext.userState.id) {
            setChangeBaselineRiskEstimatesModalState({ "modalOpen": true });
          } else {
            alert("Please login to use this function.");
          }
        }}
        disabled={previousVersionLoaded}
      />
      &nbsp;&nbsp;&nbsp;
      <>
        Add individualized estimates for the baseline risk (risk without treatment) for any of the outcomes, entered as a percentage (from 0 to 100).
      </>
    </span>
  </>
});

//generate text.div from entry values for the table row sent as sections and returned as newRowSections
const generateTableRowContent = async (sections, globalContext, sourceJsonState) => {
  let resourceDictionary = sourceJsonState?.resourceDictionary || {};
  let sourceReferences = {
    outcomeDefinition: "", interventionOnlyEvidence: "", comparatorOnlyEvidence: "",
    comparativeEvidence: "", outcomeImportance: ""
  };
  for (let section of sections) {
    let sectionCode;
    if (section?.code) {
      sectionCode = section.code.text || section.code.coding?.[0]?.code;
    }
    if (sectionCode === "outcome-measure" || sectionCode === "EvidenceVariable-outcome") {
      if (section.entry?.length > 0) {
        sourceReferences.outcomeDefinition = section.entry[0];
      }
    }
    if (sectionCode === "result-with-comparator-alone" || sectionCode === "Control-group-alone-Evidence" ||
      sectionCode === "evidence-with-comparator-alone") {
      if (section.entry?.length > 0) {
        sourceReferences.comparatorOnlyEvidence = section.entry[0];
      }
    }
    if (sectionCode === "result-with-intervention-alone" || sectionCode === "Intervention-group-alone-Evidence" ||
      sectionCode === "evidence-with-intervention-alone") {
      if (section.entry?.length > 0) {
        sourceReferences.interventionOnlyEvidence = section.entry[0];
      }
    }
    if (sectionCode === "result-with-intervention-vs-comparator" || sectionCode === "Intervention-vs-Control-Evidence" ||
      sectionCode === "evidence-with-intervention-vs-comparator") {
      if (section.entry?.length > 0) {
        sourceReferences.comparativeEvidence = section.entry[0];
      }
    }
    if (sectionCode === "relative-importance") {
      if (section.entry?.length > 0) {
        sourceReferences.outcomeImportance = section.entry[0];
      }
    }
  }

  let newRowSections = JSON.parse(JSON.stringify(sections));
  if (Array.isArray(newRowSections) && newRowSections.length > 0) {
    let outcomeDefinitionJson;
    let comparatorOnlyEvidenceJson;
    let interventionOnlyEvidenceJson;
    let comparativeEvidenceJson;
    let outcomeImportanceJson;
    if (sourceReferences.outcomeDefinition) {
      let outcomeDefinitionFoi = sourceReferences.outcomeDefinition.reference?.split('/')[1] || "missing";
      if ((outcomeDefinitionFoi === "missing" || isNaN(outcomeDefinitionFoi)) &&
        sourceReferences.outcomeDefinition.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.outcomeDefinition.identifier.value) {
        let outcomeDefinitionFli = sourceReferences.outcomeDefinition.identifier.value;
        if (resourceDictionary.fliToFoi[outcomeDefinitionFli]) {
          outcomeDefinitionFoi = resourceDictionary.fliToFoi[outcomeDefinitionFli];
          sourceReferences.outcomeDefinition.reference = "Evidence/" + outcomeDefinitionFoi;
        }
      }

      const body = {
        'functionid': 'getfhirresource',
        'resourceid': outcomeDefinitionFoi,
        'resourcetype': "EvidenceVariable",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        outcomeDefinitionJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferences.comparatorOnlyEvidence) {
      let comparatorOnlyEvidenceFoi = sourceReferences.comparatorOnlyEvidence.reference?.split('/')[1] || "missing";
      if ((comparatorOnlyEvidenceFoi === "missing" || isNaN(comparatorOnlyEvidenceFoi)) &&
        sourceReferences.comparatorOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.comparatorOnlyEvidence.identifier.value) {
        let comparatorOnlyEvidenceFli = sourceReferences.comparatorOnlyEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli]) {
          comparatorOnlyEvidenceFoi = resourceDictionary.fliToFoi[comparatorOnlyEvidenceFli];
          sourceReferences.comparatorOnlyEvidence.reference = "Evidence/" + comparatorOnlyEvidenceFoi;
        }
      }
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': comparatorOnlyEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        comparatorOnlyEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferences.interventionOnlyEvidence) {
      let interventionOnlyEvidenceFoi = sourceReferences.interventionOnlyEvidence.reference?.split('/')[1] || "missing";
      if ((interventionOnlyEvidenceFoi === "missing" || isNaN(interventionOnlyEvidenceFoi)) &&
        sourceReferences.interventionOnlyEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.interventionOnlyEvidence.identifier.value) {
        let interventionOnlyEvidenceFli = sourceReferences.interventionOnlyEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[interventionOnlyEvidenceFli]) {
          interventionOnlyEvidenceFoi = resourceDictionary.fliToFoi[interventionOnlyEvidenceFli];
          sourceReferences.interventionOnlyEvidence.reference = "Evidence/" + interventionOnlyEvidenceFoi;
        }
      }
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': interventionOnlyEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        interventionOnlyEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferences.comparativeEvidence) {
      let comparativeEvidenceFoi = sourceReferences.comparativeEvidence.reference?.split('/')[1] || "missing";
      if ((comparativeEvidenceFoi === "missing" || isNaN(comparativeEvidenceFoi)) &&
        sourceReferences.comparativeEvidence.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.comparativeEvidence.identifier.value) {
        let comparativeEvidenceFli = sourceReferences.comparativeEvidence.identifier.value;
        if (resourceDictionary.fliToFoi[comparativeEvidenceFli]) {
          comparativeEvidenceFoi = resourceDictionary.fliToFoi[comparativeEvidenceFli];
          sourceReferences.comparativeEvidence.reference = "Evidence/" + comparativeEvidenceFoi;
        }
      }
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': comparativeEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        comparativeEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferences.outcomeImportance) {
      let outcomeImportanceFoi = sourceReferences.outcomeImportance.reference?.split('/')[1] || "missing";
      if ((outcomeImportanceFoi === "missing" || isNaN(outcomeImportanceFoi)) &&
        sourceReferences.outcomeImportance.identifier?.system === "https://fevir.net/FLI" &&
        sourceReferences.outcomeImportance.identifier.value) {
        let outcomeImportanceFli = sourceReferences.outcomeImportance.identifier.value;
        if (resourceDictionary.fliToFoi[outcomeImportanceFli]) {
          outcomeImportanceFoi = resourceDictionary.fliToFoi[outcomeImportanceFli];
          sourceReferences.outcomeImportance.reference = "ArtifactAssessment/" + outcomeImportanceFoi;
        }
      }
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': outcomeImportanceFoi,
        'resourcetype': "ArtifactAssessment",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        outcomeImportanceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    for (let section of newRowSections) {
      if (section.code?.coding && section.code.coding[0].code) {
        switch (section.code.coding[0].code) {
          case "outcome-measure":
            if (outcomeDefinitionJson) {
              section.entry = [sourceReferences.outcomeDefinition];
              let outcomeName = outcomeDefinitionJson.title || outcomeDefinitionJson.name || '[No title found.]';
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + outcomeName.replace('OutcomeDefinition: ', '') + "</p></div>"
              };
            }
            break;
          case "EvidenceVariable-outcome":
            if (outcomeDefinitionJson) {
              section.entry = [sourceReferences.outcomeDefinition];
              let outcomeName = outcomeDefinitionJson.title || outcomeDefinitionJson.name || '[No title found.]';
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + outcomeName.replace('OutcomeDefinition: ', '') + "</p></div>"
              };
            }
            break;
          case "sample-size":
            generateSectionTextForSampleSize(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "SampleSize":
            generateSectionTextForSampleSize(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "result-with-comparator-alone":
            if (comparatorOnlyEvidenceJson) {
              section.entry = [sourceReferences.comparatorOnlyEvidence];
              generateSectionTextForPercentage(section, comparatorOnlyEvidenceJson.statistic);
            }
            break;
          case "evidence-with-comparator-alone":
            if (comparatorOnlyEvidenceJson) {
              section.entry = [sourceReferences.comparatorOnlyEvidence];
              generateSectionTextForPercentage(section, comparatorOnlyEvidenceJson.statistic);
            }
            break;
          case "Control-group-alone-Evidence":
            if (comparatorOnlyEvidenceJson) {
              section.entry = [sourceReferences.comparatorOnlyEvidence];
              generateSectionTextForPercentage(section, comparatorOnlyEvidenceJson.statistic);
            }
            break;
          case "result-with-intervention-alone":
            if (interventionOnlyEvidenceJson) {
              section.entry = [sourceReferences.interventionOnlyEvidence];
              generateSectionTextForPercentage(section, interventionOnlyEvidenceJson.statistic);
            }
            break;
          case "evidence-with-intervention-alone":
            if (interventionOnlyEvidenceJson) {
              section.entry = [sourceReferences.interventionOnlyEvidence];
              generateSectionTextForPercentage(section, interventionOnlyEvidenceJson.statistic);
            }
            break;
          case "Intervention-group-alone-Evidence":
            if (interventionOnlyEvidenceJson) {
              section.entry = [sourceReferences.interventionOnlyEvidence];
              generateSectionTextForPercentage(section, interventionOnlyEvidenceJson.statistic);
            }
            break;
          case "result-with-intervention-vs-comparator":
            generateSectionTextForEffectEstimate(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "Intervention-vs-Control-Evidence":
            generateSectionTextForEffectEstimate(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "certainty-of-evidence":
            generateSectionTextForCertaintyOfEvidence(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "Certainty-of-Evidence":
            generateSectionTextForCertaintyOfEvidence(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "assertion":
            generateSectionTextForAssertion(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "Assertion":
            generateSectionTextForAssertion(section, comparativeEvidenceJson, sourceReferences);
            break;
          case "result-with-intervention-alone-calculated":
            generateSectionTextForCalculatedInterventionResult(section, comparativeEvidenceJson,
              comparatorOnlyEvidenceJson, sourceReferences);
            break;
          case "relative-importance":
            generateSectionTextForRelativeImportance(section, outcomeImportanceJson, sourceReferences);
            break;
          default:
            console.log("UNRECOGNIZED section code: " + section.code.coding[0].code);
        }
      } else if (section.code?.text) {
        if (section.code.text === "calculated value derived from the control group and effect estimate") {
          generateSectionTextForCalculatedInterventionResult(section, comparativeEvidenceJson,
            comparatorOnlyEvidenceJson, sourceReferences);
        } else if (section.code.text === "relative importance of outcome") {
          generateSectionTextForRelativeImportance(section, outcomeImportanceJson, sourceReferences);
        } else {
          console.log("UNRECOGNIZED section code text: " + section.code.text);
        }
      }
    }
    return newRowSections;
  } else {
    console.log('newRowSections is not an array.')
    console.log(newRowSections);
  }
};

let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];

const ColumnsSectionDataEntry = ({ startingValue, setResourceState }) => {

  let startingColumnsSection = {
    "title": "Column Headers",
    "code": sectionCodeColumnHeaders,
    "author": [],
    "focus": {},
    "text": {},
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": [
      {
        "title": "Outcome",
        "code": sectionCodeOutcomeMeasure,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Outcome</div>"
        }
      },
      {
        "title": "Sample Size",
        "code": sectionCodeSampleSize,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sample size: # studies, # participants, # counted, # events</div>"
        }
      },
      {
        "title": "Result Without Treatment",
        "code": sectionCodeResultWithComparatorAlone,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result Without Treatment</div>"
        }
      },
      {
        "title": "Result With Treatment--Observed",
        "code": sectionCodeResultWithInterventionAlone,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Observed)</div>"
        }
      },
      {
        "title": "Result With Treatment--Derived",
        "code": sectionCodeResultWithInterventionAloneCalculated,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Result With Treatment (Calculated)</div>"
        }
      },
      {
        "title": "Effect Estimate",
        "code": sectionCodeResultWithInterventionVsComparator,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Effect Estimate</div>"
        }
      },
      {
        "title": "Certainty of Evidence",
        "code": sectionCodeCertaintyOfEvidence,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Certainty of finding (Quality of evidence)</div>"
        }
      },
      {
        "title": "Assertion",
        "code": sectionCodeAssertion,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">What this means</div>"
        }
      }
    ]
  };
  if (startingValue && startingValue[0]) {
    if (startingValue[0].title) { startingColumnsSection.title = startingValue[0].title; }
    if (startingValue[0].author) { startingColumnsSection.author = startingValue[0].author; }
    if (startingValue[0].focus) { startingColumnsSection.focus = startingValue[0].focus; }
    if (startingValue[0].text) { startingColumnsSection.text = startingValue[0].text; }
    if (startingValue[0].orderedBy) { startingColumnsSection.orderedBy = startingValue[0].orderedBy; }
    if (startingValue[0].entry) { startingColumnsSection.entry = startingValue[0].entry; }
    if (startingValue[0].emptyReason) { startingColumnsSection.emptyReason = startingValue[0].emptyReason; }
    if (startingValue[0].section) { startingColumnsSection.section = startingValue[0].section; }
  } else {
    delete startingColumnsSection.author;
    delete startingColumnsSection.focus;
    delete startingColumnsSection.text;
    delete startingColumnsSection.orderedBy;
    delete startingColumnsSection.entry;
    delete startingColumnsSection.emptyReason;
  }

  const [columnsSectionState, setColumnsSectionState] = useState(JSON.parse(JSON.stringify(startingColumnsSection || {})));

  useEffect(() => {
    let newSection = startingValue;
    newSection[0] = JSON.parse(JSON.stringify(columnsSectionState));
    if (newSection[0].section?.length || newSection[0].entry?.length ||
      (newSection[0].text && newSection[0].text.status && newSection[0].text.status !== "empty")) {
      delete newSection[0].emptyReason;
    } else if (!newSection[0].emptyReason) {
      newSection[0].emptyReason = emptyReasonNotStarted;
    }
    setResourceState(prevState => { return { ...prevState, "section": newSection } });
  }, [columnsSectionState]);

  return <>
    <SummaryOfFindingsSubsectionDataEntry startingValue={columnsSectionState.section}
      setResourceState={setColumnsSectionState} topSection={"Column-Headers"} />
  </>
}

const OutcomeSectionDataEntry = ({ sectionIndex, startingValue, setArrayState }) => {

  let startingOutcomeSection = {
    "title": "Outcome Table Row " + sectionIndex,
    "code": sectionCodeSummaryOfFindingsForSingleOutcome,
    "author": [],
    "focus": {},
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": [
      {
        "title": "Outcome",
        "code": sectionCodeOutcomeMeasure
      },
      {
        "title": "Sample Size",
        "code": sectionCodeSampleSize
      },
      {
        "title": "Result Without Treatment",
        "code": sectionCodeResultWithComparatorAlone
      },
      {
        "title": "Result With Treatment--Observed",
        "code": sectionCodeResultWithInterventionAlone
      },
      {
        "title": "Result With Treatment--Derived",
        "code": sectionCodeResultWithInterventionAloneCalculated
      },
      {
        "title": "Effect Estimate",
        "code": sectionCodeResultWithInterventionVsComparator
      },
      {
        "title": "Certainty of Evidence",
        "code": sectionCodeCertaintyOfEvidence
      },
      {
        "title": "Assertion",
        "code": sectionCodeAssertion
      }
    ]
  };
  if (startingValue) {
    if (startingValue.title) { startingOutcomeSection.title = startingValue.title; }
    if (startingValue.author) { startingOutcomeSection.author = startingValue.author; }
    if (startingValue.focus) { startingOutcomeSection.focus = startingValue.focus; }
    if (startingValue.text) { startingOutcomeSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingOutcomeSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingOutcomeSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingOutcomeSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingOutcomeSection.section = startingValue.section; }
  } else {
    delete startingOutcomeSection.author;
    delete startingOutcomeSection.focus;
    delete startingOutcomeSection.text;
    delete startingOutcomeSection.orderedBy;
    delete startingOutcomeSection.entry;
    delete startingOutcomeSection.emptyReason;
  }

  const [outcomeSectionState, setOutcomeSectionState] = useState(JSON.parse(JSON.stringify(startingOutcomeSection || {})));
  const [expandAuthorEntryState, setExpandAuthorEntryState] = useState(false);

  useEffect(() => {
    let newSection = JSON.parse(JSON.stringify(outcomeSectionState));
    newSection = autoEditEmptyReason(newSection);
    setArrayState(prevState => { return { ...prevState, [sectionIndex]: newSection } });
  }, [outcomeSectionState]);

  return <>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='title' fieldLabel='Outcome Section Title'
        startingValue={outcomeSectionState.title} setResourceState={setOutcomeSectionState} />
      <DataEntry datatype='Narrative' elementName='text' fieldLabel='Outcome Table Row Summary (Leave empty unless wanting to replace the table row with one text summary.)'
        startingValue={outcomeSectionState.text} setResourceState={setOutcomeSectionState} />
      <span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
        {expandAuthorEntryState ?
          <>Collapse Outcome Table Row Author entry ▼</>
          :
          <>Expand to add or edit Outcome Table Row Authors ►</>}
      </span>
      {expandAuthorEntryState && <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel='Outcome Table Row Author'
        startCollapsed startEmptyArrayClosed enableCreation={true}
        startingValue={outcomeSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
        setResourceState={setOutcomeSectionState} />}
      <DataEntry datatype='Reference' elementName='focus' fieldLabel='Section Focus (OutcomeDefinition Resource)'
        startingValue={outcomeSectionState.focus} startCollapsed enableCreation={true}
        setResourceState={setOutcomeSectionState} />
    </div>
  </>
};

const OutcomesSectionDataEntry = ({ startingValue, setResourceState }) => {
  let startingSectionAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingSectionAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    startingSectionAsObject['0'] = null;
  }

  const [arrayState, setArrayState] = useState(JSON.parse(JSON.stringify(startingSectionAsObject || {})));

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null && !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) &&
          !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      setResourceState(prevState => {
        let newSection = prevState.section;
        newSection[1].section = newArray;
        return { ...prevState, "section": newSection }
      });
    }
  }), [arrayState]);

  return <div>
    <div>
      {Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
        return <div key={keyValuePairIndex}>{keyValuePairIndex > 0 &&
          <div>
            {keyValuePair[1] === "DELETEME" ?
              <>
                <p><b>{'Outcome Section entry ' + keyValuePairIndex}: </b></p>
                <div style={{ marginLeft: "24px" }}>WILL BE DELETED.</div>
              </>
              :
              <div>
                <p>Outcome Section entry {keyValuePairIndex}</p>
                <div style={{ marginLeft: "24px" }}>
                  <OutcomeSectionDataEntry sectionIndex={keyValuePair[0]}
                    startingValue={keyValuePair[1]} setArrayState={setArrayState} />
                </div>
              </div>
            }
            <span style={{ marginLeft: "24px" }}>
              <Button className="formButton" style={{ color: "#000000" }} content="x Delete this Outcome Table Row"
                onClick={() => {
                  setArrayState(prevState => {
                    return {
                      ...prevState,
                      [keyValuePairIndex.toString()]: "DELETEME"
                    };
                  });
                }} />
              <br /><br /><br />
            </span>
          </div>
        }</div>
      })}
      <br />
      <Button className="formButton" style={{ color: "#000000" }} content="+ Add Outcome Table Row"
        onClick={() => {
          setArrayState(prevState => {
            let arrayLength = Object.keys(prevState).length;
            let arrayIndexNumber = arrayLength.toString();
            if (prevState['0'] === null) {
              arrayIndexNumber = '1';
            }
            return {
              ...prevState,
              [arrayIndexNumber]: ""
            };
          });
        }} />
    </div>
  </div>
};

const CompositionTableCellArrayEntry = ({ elementName, fieldLabel, startingValue, setResourceState,
  columnHeaders, dataEntryStyle, addTableRowModalState }) => {
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
      if (itemIndex === '0') {
        if (addTableRowModalState.newRowTitle ||
          !(typeof addTableRowModalState.newRowFocus === "object" && Object.keys(addTableRowModalState.newRowFocus).length === 1 && addTableRowModalState.newRowFocus["display"] === "[No data]")) {
          if (addTableRowModalState.newRowTitle) {
            startingArrayAsObject['0'].text = {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + addTableRowModalState.newRowTitle + "</p></div>"
            };
          }

          if (!(typeof addTableRowModalState.newRowFocus === "object" && Object.keys(addTableRowModalState.newRowFocus).length === 1 && addTableRowModalState.newRowFocus["display"] === "[No data]")) {
            startingArrayAsObject['0'].entry = [addTableRowModalState.newRowFocus];
          }
          delete startingArrayAsObject['0'].emptyReason;
        }
      }
    }
  } else {
    startingArrayAsObject['0'] = null;
  }

  const [arrayState, setArrayState] = useState(JSON.parse(JSON.stringify(startingArrayAsObject || {})));

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null && !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) &&
          !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      setResourceState(prevState => { return { ...prevState, [elementName]: newArray } });
    }
  }), [arrayState]);

  return <div>
    {fieldLabel && <p><b>{fieldLabel}: </b></p>}
    <div style={{ marginLeft: "24px" }}>
      {Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
        let title = keyValuePair[1].title;
        let columnHeader = columnHeaders[title];
        return <div key={keyValuePairIndex}>
          {keyValuePair[0] === "0" ? <></> :
            <DataEntry datatype="CompositionTableCell" elementName={keyValuePair[0]}
              fieldLabel={columnHeader} dataEntryStyle={dataEntryStyle} addTableRowModalState={addTableRowModalState}
              startingValue={keyValuePair[1]} setResourceState={setArrayState} />
          }
        </div>
      })}
    </div>
  </div>
};

const generateTableCellContent = async (sourceReferencesState, newRowSectionsState, setNewRowSectionsState, globalContext) => {

  let newRowSections = JSON.parse(JSON.stringify(newRowSectionsState.newRowSections));
  if (Array.isArray(newRowSections) && newRowSections.length > 0) {
    let outcomeDefinitionJson;
    let comparatorOnlyEvidenceJson;
    let interventionOnlyEvidenceJson;
    let comparativeEvidenceJson;
    let outcomeImportanceJson;
    if (sourceReferencesState.outcomeDefinition) {
      let outcomeDefinitionFoi = sourceReferencesState.outcomeDefinition.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': outcomeDefinitionFoi,
        'resourcetype': "EvidenceVariable",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        outcomeDefinitionJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.comparatorOnlyEvidence) {
      let comparatorOnlyEvidenceFoi = sourceReferencesState.comparatorOnlyEvidence.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': comparatorOnlyEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        comparatorOnlyEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.interventionOnlyEvidence) {
      let interventionOnlyEvidenceFoi = sourceReferencesState.interventionOnlyEvidence.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': interventionOnlyEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        interventionOnlyEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.comparativeEvidence) {
      let comparativeEvidenceFoi = sourceReferencesState.comparativeEvidence.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': comparativeEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        comparativeEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.outcomeImportance) {
      let outcomeImportanceFoi = sourceReferencesState.outcomeImportance.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': outcomeImportanceFoi,
        'resourcetype': "ArtifactAssessment",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        outcomeImportanceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    for (let section of newRowSections) {
      if (section.code?.coding && section.code.coding[0].code) {
        switch (section.code.coding[0].code) {
          case "outcome-measure":
            if (outcomeDefinitionJson) {
              section.entry = [sourceReferencesState.outcomeDefinition];
              let outcomeName = outcomeDefinitionJson.title || outcomeDefinitionJson.name || '[No title found.]';
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + outcomeName.replace('OutcomeDefinition: ', '') + "</p></div>"
              };
            }
            break;
          case "EvidenceVariable-outcome":
            if (outcomeDefinitionJson) {
              section.entry = [sourceReferencesState.outcomeDefinition];
              let outcomeName = outcomeDefinitionJson.title || outcomeDefinitionJson.name || '[No title found.]';
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + outcomeName.replace('OutcomeDefinition: ', '') + "</p></div>"
              };
            }
            break;
          case "sample-size":
            generateSectionTextForSampleSize(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "SampleSize":
            generateSectionTextForSampleSize(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "result-with-comparator-alone":
            if (comparatorOnlyEvidenceJson) {
              section.entry = [sourceReferencesState.comparatorOnlyEvidence];
              generateSectionTextForPercentage(section, comparatorOnlyEvidenceJson.statistic);
            }
            break;
          case "Control-group-alone-Evidence":
            if (comparatorOnlyEvidenceJson) {
              section.entry = [sourceReferencesState.comparatorOnlyEvidence];
              generateSectionTextForPercentage(section, comparatorOnlyEvidenceJson.statistic);
            }
            break;
          case "result-with-intervention-alone":
            if (interventionOnlyEvidenceJson) {
              section.entry = [sourceReferencesState.interventionOnlyEvidence];
              generateSectionTextForPercentage(section, interventionOnlyEvidenceJson.statistic);
            }
            break;
          case "Intervention-group-alone-Evidence":
            if (interventionOnlyEvidenceJson) {
              section.entry = [sourceReferencesState.interventionOnlyEvidence];
              generateSectionTextForPercentage(section, interventionOnlyEvidenceJson.statistic);
            }
            break;
          case "result-with-intervention-vs-comparator":
            generateSectionTextForEffectEstimate(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "Intervention-vs-Control-Evidence":
            generateSectionTextForEffectEstimate(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "certainty-of-evidence":
            generateSectionTextForCertaintyOfEvidence(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "Certainty-of-Evidence":
            generateSectionTextForCertaintyOfEvidence(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "assertion":
            generateSectionTextForAssertion(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "Assertion":
            generateSectionTextForAssertion(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "result-with-intervention-alone-calculated":
            generateSectionTextForCalculatedInterventionResult(section, comparativeEvidenceJson,
              comparatorOnlyEvidenceJson, sourceReferencesState);
            break;
          case "relative-importance":
            generateSectionTextForRelativeImportance(section, outcomeImportanceJson, sourceReferencesState);
            break;
          default:
            console.log("UNRECOGNIZED section code: " + section.code.coding[0].code);
        }
      } else if (section.code?.text) {
        if (section.code.text === "calculated value derived from the control group and effect estimate") {
          generateSectionTextForCalculatedInterventionResult(section, comparativeEvidenceJson,
            comparatorOnlyEvidenceJson, sourceReferencesState);
        } else if (section.code.text === "relative importance of outcome") {
          generateSectionTextForRelativeImportance(section, outcomeImportanceJson, sourceReferencesState);
        } else {
          console.log("UNRECOGNIZED section code text: " + section.code.text);
        }
      }
    }
    setNewRowSectionsState({ newRowSections: newRowSections, tableRowContentGenerated: true });
  } else {
    console.log(newRowSections);
  }
};

const AddTableRowModal = ({ startingValue, setResourceState, globalContext, customized, setOpenAddTableRowModalState }) => {
  let failOut = false;

  let columnTitles = [];
  let columnHeaders = {};
  let columnCodes = [];
  if (Array.isArray(startingValue) && startingValue[0]?.code?.coding?.[0]?.code.toLowerCase() === "column-headers" && Array.isArray(startingValue[0].section)) {
    columnTitles = startingValue[0].section.map(section => section.title);
    columnCodes = startingValue[0].section.map(section => section.code);
    for (const section of startingValue[0].section) {
      columnHeaders[section.title] = section.text.div;
    }
  } else {
    alert("The Composition does not have an initial section coded for column headers.");
    failOut = true;
  }

  let tableRowSectionCode = { "text": "Summary of findings entry for a single outcome" };

  let startingNewRowSections = [];
  for (let i in columnTitles) {
    let tableCellSection = {
      "title": columnTitles[i],
      "code": columnCodes[i],
      "text": emptyTextNoData,
      "entry": [],
      "emptyReason": emptyReasonNotStarted
    }
    startingNewRowSections.push(tableCellSection);
  }

  const [addTableRowModalState, setAddTableRowModalState] = useState({
    "newRowTitle": "",
    "newRowFocus": "",
    "newRowSections": JSON.parse(JSON.stringify(startingNewRowSections))
  });
  const [newRowSectionsState, setNewRowSectionsState] = useState({ "newRowSections": JSON.parse(JSON.stringify(startingNewRowSections)) });
  const [sourceReferencesState, setSourceReferencesState] = useState({
    outcomeDefinition: "", interventionOnlyEvidence: "", comparatorOnlyEvidence: "",
    comparativeEvidence: "", outcomeImportance: ""
  });

  const submitNewRowChange = () => {
    if (addTableRowModalState.newRowTitle) {
      setResourceState(prevState => {
        let newRow = {
          "title": addTableRowModalState.newRowTitle,
          "code": tableRowSectionCode,
          "focus": addTableRowModalState.newRowFocus || { "display": "[No data]" },
          "section": newRowSectionsState.newRowSections
        }
        let newSection = JSON.parse(JSON.stringify(startingValue));
        newSection[1].section.push(newRow);
        return { ...prevState, "section": newSection, "sectionChanged": true };
      });
      setOpenAddTableRowModalState(prevState => { return { ...prevState, openModal: false }; });
    }
  }

  useEffect(() => {
    if (newRowSectionsState.tableRowContentGenerated) {
      submitNewRowChange();
    }
  }, [newRowSectionsState]);


  useEffect((() => {
    if (addTableRowModalState.newRowTitle || !(typeof addTableRowModalState.newRowFocus === "object" && Object.keys(addTableRowModalState.newRowFocus).length === 1 && addTableRowModalState.newRowFocus["display"] === "[No data]")) {
      let updatedNewRowSections = JSON.parse(JSON.stringify(newRowSectionsState.newRowSections));
      if (addTableRowModalState.newRowTitle) {
        updatedNewRowSections[0].text = {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + addTableRowModalState.newRowTitle + "</p></div>"
        };
      }
      if (!(typeof addTableRowModalState.newRowFocus === "object" && Object.keys(addTableRowModalState.newRowFocus).length === 1 && addTableRowModalState.newRowFocus["display"] === "[No data]")) {
        updatedNewRowSections[0].entry = [addTableRowModalState.newRowFocus];
      }
      setNewRowSectionsState({ "newRowSections": updatedNewRowSections });
    }
  }), [addTableRowModalState]);

  useEffect(() => {
    if (sourceReferencesState.outcomeDefinition) {
      let newRowFocus = JSON.parse(JSON.stringify(sourceReferencesState.outcomeDefinition))
      setAddTableRowModalState(prevState => { return { ...prevState, newRowFocus: newRowFocus } });
    }
  }, [sourceReferencesState]);

  if (failOut) {
    return <></>
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setOpenAddTableRowModalState(prevState => { return { ...prevState, openModal: false }; });
      }} />
    <h3>Name the Outcome (how it will show in the table row header)</h3>
    <br />
    <DataEntry datatype='string' elementName='newRowTitle' fieldLabel='Title' required
      startingValue={addTableRowModalState.newRowTitle} setResourceState={setAddTableRowModalState} />
    <br />
    <h3>Set or create the structured Outcome Definition</h3>
    <br />
    <DataEntry datatype='Reference' elementName='outcomeDefinition' enableCreation={true}
      fieldLabel='OutcomeDefinition (Resource Reference)' referencedResourceTypes={['EvidenceVariable']}
      startingResourceType="EvidenceVariable" setProfile="VariableDefinition"
      startingValue={sourceReferencesState.outcomeDefinition} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Comparator Only Evidence</h3>
    <br />
    <DataEntry datatype='Reference' elementName='comparatorOnlyEvidence' enableCreation={true}
      fieldLabel='ComparatorOnlyEvidence (Resource Reference)' referencedResourceTypes={['Evidence']}
      startingResourceType="Evidence" setProfile="ComparatorOnlyEvidence"
      addElementValues={{
        variableDefinition: [
          {
            "description": "comparator only group",
            "variableRole": "population"
          },
          {
            "description": sourceReferencesState.outcomeDefinition?.display,
            "variableRole": "outcome",
            "observed": sourceReferencesState.outcomeDefinition
          }
        ]
      }}
      startingValue={sourceReferencesState.comparatorOnlyEvidence} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Intervention Only Evidence</h3>
    <br />
    <DataEntry datatype='Reference' elementName='interventionOnlyEvidence' enableCreation={true}
      fieldLabel='InterventionOnlyEvidence (Resource Reference)' referencedResourceTypes={['Evidence']}
      startingResourceType="Evidence" setProfile="InterventionOnlyEvidence"
      addElementValues={{
        variableDefinition: [
          {
            "description": "intervention only group",
            "variableRole": "population"
          },
          {
            "description": sourceReferencesState.outcomeDefinition?.display,
            "variableRole": "outcome",
            "observed": sourceReferencesState.outcomeDefinition
          }
        ]
      }}
      startingValue={sourceReferencesState.interventionOnlyEvidence} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Comparative Evidence</h3>
    <br />
    <DataEntry datatype='Reference' elementName='comparativeEvidence' enableCreation={true}
      fieldLabel='ComparativeEvidence (Resource Reference)' referencedResourceTypes={['Evidence']}
      startingResourceType="Evidence" setProfile="ComparativeEvidence"
      addElementValues={{
        variableDefinition: [
          {
            "description": "total group",
            "variableRole": "population"
          },
          {
            "description": "intervention group vs. comparator group",
            "variableRole": "exposure"
          },
          {
            "description": sourceReferencesState.outcomeDefinition?.display,
            "variableRole": "outcome",
            "observed": sourceReferencesState.outcomeDefinition
          }
        ]
      }}
      startingValue={sourceReferencesState.comparativeEvidence} setResourceState={setSourceReferencesState} />
    <br />
    {customized && <>
      <h3>Set or create the structured Outcome Importance (Rating)</h3>
      <br />
      <DataEntry datatype='Reference' elementName='outcomeImportance' enableCreation={true}
        fieldLabel='Outcome Importance Rating (Resource Reference)' referencedResourceTypes={['ArtifactAssessment']}
        startingValue={sourceReferencesState.outcomeImportance} setResourceState={setSourceReferencesState} />
      <br />
    </>}
    <br />
    {sourceReferencesState.comparativeEvidence?.reference &&
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
        content="Generate Table Cell Content" positive
        onClick={async () => {
          await generateTableCellContent(sourceReferencesState, newRowSectionsState, setNewRowSectionsState, globalContext);
        }}
      />}
    <br />
    <br />
    <h3>Add Table Cell Content</h3>
    <br />
    <CompositionTableCellArrayEntry elementName='newRowSections' startingValue={newRowSectionsState.newRowSections}
      setResourceState={setNewRowSectionsState} columnHeaders={columnHeaders}
      dataEntryStyle="SummaryOfFindings" addTableRowModalState={addTableRowModalState} />
    <br /><br /><span>
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
        content="Submit Changes" positive
        onClick={submitNewRowChange}
        disabled={!addTableRowModalState.newRowTitle}
      />
      &nbsp;&nbsp;
      {!addTableRowModalState.newRowTitle && <>Enter a title at the top to submit changes.</>}
    </span>
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px", height: "96%" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={true}
      centered={false}
      content={modalContent}
    />
  )
};

const EditTableCellModal = ({ item, itemIndex, entryIndex, startingValue, setOpenEditModalState, setResourceState }) => {
  let startingEditModalState = {
    "textStatus": "", "textDiv": "", "entryList": []
  };

  if (startingValue && startingValue[entryIndex] &&
    startingValue[entryIndex]["section"] &&
    startingValue[entryIndex]["section"][itemIndex]) {
    let startingSubsection = startingValue[entryIndex]["section"][itemIndex];
    startingEditModalState.textStatus = startingSubsection.text?.status;
    startingEditModalState.textDiv = startingSubsection.text?.div || "";
    startingEditModalState.entryList = startingSubsection.entry ?? [];
    if ((startingEditModalState.textStatus === "" || startingEditModalState.textStatus === "empty") &&
      (startingEditModalState.textDiv === "" || clearDivWrapper(startingEditModalState.textDiv) === "[No data]") &&
      startingEditModalState.entryList.length === 0) {
      startingEditModalState.emptyReason = startingSubsection.emptyReason || emptyReasonNotStarted;
    }
  }
  let startingDivTextValue = clearDivWrapper(startingEditModalState.textDiv);

  let startingDivValue = { "divText": startingDivTextValue };

  const [editModalState, setEditModalState] = useState(JSON.parse(JSON.stringify(startingEditModalState || {})));
  const [narrativeDivState, setNarrativeDivState] = useState(JSON.parse(JSON.stringify(startingDivValue || {})));

  useEffect((() => {
    if (startingDivValue.divText !== '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>") {
      if ((narrativeDivState.divText || narrativeDivState.divText === "0") &&
        narrativeDivState.divText !== '[No data]' &&
        narrativeDivState.divText !== '[No data.]' &&
        narrativeDivState.divText !== '<p>[No data]</p>' &&
        narrativeDivState.divText !== '<p>[No data.]</p>') {
        setEditModalState(prevState => { return { ...prevState, "textStatus": "additional", "textDiv": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
      } else {
        setEditModalState(prevState => { return { ...prevState, "textStatus": "empty", "textDiv": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
      }
    }
  }), [narrativeDivState]);

  const submitChange = () => {
    let newSubsection = {};
    try {
      newSubsection = startingValue[entryIndex]["section"][itemIndex];
    } catch { }
    if (clearDivWrapper(editModalState.textDiv) && clearDivWrapper(editModalState.textDiv) !== "0" &&
      clearDivWrapper(editModalState.textDiv) !== "[No data]" &&
      clearDivWrapper(editModalState.textDiv) !== "[No data.]" &&
      clearDivWrapper(editModalState.textDiv) !== "<p>[No data]</p>" &&
      clearDivWrapper(editModalState.textDiv) !== "<p>[No data.]</p>") {
      newSubsection.text = { "status": "additional", "div": editModalState.textDiv };
    } else {
      newSubsection.text = JSON.parse(JSON.stringify(emptyTextNoData));
    }
    if (Array.isArray(editModalState.entryList) && editModalState.entryList.length > 0) {
      newSubsection.entry = editModalState.entryList;
    } else {
      delete newSubsection.entry;
    }
    if (editModalState.entryList.length === 0 &&
      (!editModalState.textDiv || !clearDivWrapper(editModalState.textDiv) ||
        clearDivWrapper(editModalState.textDiv) === "[No data.]" ||
        clearDivWrapper(editModalState.textDiv) === "<p>[No data.]</p>")) {
      newSubsection.emptyReason = editModalState.emptyReason || { "text": "[Data deleted]" };
    } else {
      delete newSubsection.emptyReason;
    }
    let newFindingsSection = JSON.parse(JSON.stringify(startingValue));
    newFindingsSection[entryIndex]["section"][itemIndex] = newSubsection;
    setResourceState(prevState => {
      let newSection = prevState.section;
      newSection[1].section = newFindingsSection;
      return { ...prevState, "section": newSection, "sectionChanged": true };
    });
    setOpenEditModalState(prevState => { return { ...prevState, openModal: false }; });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setOpenEditModalState(prevState => { return { ...prevState, openModal: false }; });
      }} />
    <h3>Edit Table Cell Content</h3>
    <br />
    <DataEntry datatype='string' elementName='divText' fieldLabel='Narrative Summary'
      startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState} />
    <br /><br />
    <h3>Change Resource References for the Section</h3>
    <br />
    <DataEntry asArray={true} datatype='Reference' elementName='entryList'
      fieldLabel='Resource References' enableCreation={true}
      startingValue={editModalState.entryList} setResourceState={setEditModalState} />
    {((!editModalState.textDiv || !clearDivWrapper(editModalState.textDiv) ||
      clearDivWrapper(editModalState.textDiv) === "[No data.]") &&
      (!editModalState.entryList || editModalState.entryList.length === 0)) &&
      <>
        <br /><br />
        <h3>Explain why this Table Cell is empty</h3>
        <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
          startingValue={editModalState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
          setResourceState={setEditModalState} />
      </>
    }
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Changes" positive
      onClick={submitChange}
    />
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={true}
      centered={false}
      content={modalContent}
    />
  )
};

const createCustomizedSummaryOfFindings = async (resourceState, globalContext) => {
  let newResource = JSON.parse(JSON.stringify(resourceState));
  delete newResource.resourceJson;
  delete newResource.newClassifications;
  delete newResource.targetResources;
  delete newResource.ratingDictionary;
  delete newResource.baselineRiskResources;
  newResource.resourceType = "Composition";
  if (!newResource.useContext) {
    newResource.useContext = [];
  }
  newResource.useContext.push({
    "code": {
      "system": "http://terminology.hl7.org/CodeSystem/usage-context-type",
      "code": "user",
      "display": "User Type"
    },
    "valueCodeableConcept": {
      "text": "FEvIR Platform User"
    }
  });
  let starterFOI = newResource.id;
  let oldTitle = JSON.parse(JSON.stringify(newResource.title));
  newResource.relatesTo = [{
    "type": "derived-from",
    "classifier": [compositionTypeCodeSummaryOfFindings],
    "resourceReference": {
      "reference": "Composition/" + starterFOI,
      "type": "Composition",
      "display": oldTitle
    }
  }];
  newResource.title += " customized for " + globalContext.userState.name;
  for (let section of newResource.section) {
    if (section.code.coding && section.code.coding[0].code.toLowerCase() === "column-headers") {
      section.section.push({
        "title": "Relative Importance",
        "code": sectionCodeRelativeImportance,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Relative Importance</p></div>"
        }
      });
    } else if (section.code.coding && section.code.coding[0].code.toLowerCase() === "summary-of-findings") {
      for (let findingSection of section.section) {
        findingSection.section.push({
          "title": "Relative Importance",
          "code": sectionCodeRelativeImportance,
          "emptyReason": emptyReasonNotStarted
        });
      }
    }
  }

  let newFOI = await submitResource(globalContext, newResource);
  if (newFOI) {
    return { "success": true, "newFOI": newFOI };
  }
};

const TableView = ({ tableData, openAddTableRowModalState, setOpenAddTableRowModalState, openEditModalState,
  setOpenEditModalState, customizedState, resourceState, setResourceState,
  globalContext, sourceJsonState }) => {
  return <>
    {(tableData?.Labels && tableData.Data && Object.keys(tableData.Labels).length > 0) && <>
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
        className="formButton"
        positive
        content={"Regenerate Table Content"}
        onClick={async () => {
          let newRowSectionsArray = [];
          for (let sectionIndex in resourceState.section[1].section) {
            let sections = resourceState.section[1].section[sectionIndex].section;
            let newRowSections = await generateTableRowContent(sections, globalContext, sourceJsonState);
            newRowSectionsArray.push(newRowSections);
          }
          setResourceState(prevState => {
            let newSection = prevState.section;
            for (let sectionIndex in newSection[1].section) {
              newSection[1].section[sectionIndex].section = newRowSectionsArray[sectionIndex];
            }
            return { ...prevState, section: newSection };
          });
        }}
      />
    </>}
    {openEditModalState.openModal &&
      <EditTableCellModal item={openEditModalState.item}
        itemIndex={openEditModalState.itemIndex}
        entryIndex={openEditModalState.entryIndex}
        startingValue={resourceState.section[1].section}
        setOpenEditModalState={setOpenEditModalState}
        setResourceState={setResourceState} />}
    {openAddTableRowModalState.openModal &&
      <AddTableRowModal startingValue={resourceState.section} globalContext={globalContext}
        setOpenAddTableRowModalState={setOpenAddTableRowModalState}
        setResourceState={setResourceState} customized={customizedState?.customized} />}
    {(tableData?.Labels && tableData.Data && Object.keys(tableData.Labels).length > 0) ?
      <>
        <Table>
          <Table.Header>
            <Table.Row>
              {Object.keys(tableData.Labels).map((label, labelIndex) => {
                return <Table.HeaderCell key={labelIndex}>
                  <DisplayFromFHIR xhtml={label} />
                </Table.HeaderCell>
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(tableData.Data).map((entry, entryIndex) => {
              return <Table.Row key={entryIndex}>{tableData.Data[entry]["REPLACEROW"] ?
                <Table.Cell colSpan={8}>
                  <b>Use Section Definition to edit this row. The data currently will display as: </b><DisplayFromFHIR xhtml={tableData.Data[entry]["REPLACEROW"]} />
                </Table.Cell>
                :
                Object.keys(tableData.Data[entry]).map((item, itemIndex) => {
                  return <Table.Cell key={itemIndex}>
                    <div>
                      {itemIndex !== 8 && <span className={"unselectable"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpenEditModalState({ openModal: true, item: item, itemIndex: itemIndex, entryIndex: entryIndex });
                        }}>✎ Edit</span>}
                      <br />
                      <span>
                        <DisplayFromFHIR xhtml={tableData.Data[entry][item]} />
                        {itemIndex === 0 && <div><br />
                          <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }}
                            className="formButton"
                            positive
                            content={"Regenerate Table Row"}  //If no content then it just says Generate
                            onClick={async () => {
                              let sections = resourceState.section[1].section[entryIndex].section;
                              let newRowSections = await generateTableRowContent(sections, globalContext, sourceJsonState);
                              setResourceState(prevState => {
                                let newSection = prevState.section;
                                newSection[1].section[entryIndex].section = newRowSections;
                                return { ...prevState, section: newSection };
                              });
                            }}
                          />
                        </div>}
                      </span>
                    </div>
                  </Table.Cell>
                })
              }</Table.Row>
            })}
          </Table.Body>
        </Table>
        <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
          content="+ Add Table Row" positive
          onClick={() => {
            setOpenAddTableRowModalState({ openModal: true });
          }}
        />
        <br /><br />
      </>
      :
      <>No tabular data.</>
    }
  </>
};

const reorderSections = (sectionArray, setSectionsReorderedState) => {
  let emptyColumnHeaders = {
    "title": "Column Headers",
    "code": sectionCodeColumnHeaders,
    "section": [
      {
        "title": "Outcome Measure",
        "code": sectionCodeOutcomeMeasure,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Outcome</p></div>"
        }
      },
      {
        "title": "Sample Size",
        "code": sectionCodeSampleSize,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Sample Size</p></div>"
        }
      },
      {
        "title": "Result Without Treatment",
        "code": sectionCodeResultWithComparatorAlone,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result Without Treatment</p></div>"
        }
      },
      {
        "title": "Result With Treatment--Observed",
        "code": sectionCodeResultWithInterventionAlone,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Observed)</p></div>"
        }
      },
      {
        "title": "Result With Treatment--Derived",
        "code": sectionCodeResultWithInterventionAloneCalculated,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Calculated)</p></div>"
        }
      },
      {
        "title": "Effect Estimate",
        "code": sectionCodeResultWithInterventionVsComparator,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Effect Estimate</p></div>"
        }
      },
      {
        "title": "Certainty of Evidence",
        "code": sectionCodeCertaintyOfEvidence,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Certainty of Evidence</p></div>"
        }
      },
      {
        "title": "Assertion",
        "code": sectionCodeAssertion,
        "text": {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>What this means</p></div>"
        }
      }
    ]
  };
  let emptySoF = {
    "title": "Summary of Findings",
    "code": sectionCodeSummaryOfFindings,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyGroups = {
    "title": "Groups",
    "code": sectionCodeGroups,
    "section": [
      {
        "title": "Total Group",
        "code": sectionCodePopulation,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Intervention Group",
        "code": sectionCodeInterventionGroup,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Comparator Group",
        "code": sectionCodeComparatorGroup,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Group Assignment",
        "code": sectionCodeGroupAssignment,
        "emptyReason": emptyReasonNotStarted
      }
    ]
  };
  let emptyVariables = {
    "title": "Variables (Measures)",
    "code": sectionCodeVariables,
    "emptyReason": emptyReasonNotStarted
  };
  let sectionDictionary = {
    columnHeaders: emptyColumnHeaders,
    soF: emptySoF,
    groups: emptyGroups,
    variables: emptyVariables,
    oldFindings: [],
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.coding?.[0]?.code || section?.code?.text;
    if (sectionCode === "column-headers" || sectionCode === "Column Headers") {
      sectionDictionary.columnHeaders = section;
    } else if (sectionCode === "summary-of-findings" || sectionCode === "Summary of Findings") {
      sectionDictionary.soF = section;
    } else if (sectionCode === "groups" || sectionCode === "Groups") {
      sectionDictionary.groups = section;
    } else if (sectionCode === "variables" || sectionCode === "Variables") {
      sectionDictionary.variables = section;
    } else if (sectionCode === "summary-of-findings-entry-for-a-single-outcome" ||
      sectionCode === "Summary of findings entry for a single outcome" || section.focus) {
      sectionDictionary.oldFindings.push(section);
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  if (sectionDictionary.oldFindings.length) {
    if (!sectionDictionary.soF.section) {
      sectionDictionary.soF.section = sectionDictionary.oldFindings;
    } else {
      sectionDictionary.soF.section = sectionDictionary.soF.section.concat(sectionDictionary.oldFindings);
    }
  }

  let setToTrue = false;
  if (sectionDictionary.soF.section?.length && sectionDictionary.soF.emptyReason) {
    delete sectionDictionary.soF.emptyReason;
    setToTrue = true;
  }

  let reorderedSectionArray = [
    sectionDictionary.columnHeaders,
    sectionDictionary.soF,
    sectionDictionary.groups,
    sectionDictionary.variables
  ].concat(sectionDictionary.additionalSections);
  if (setToTrue || sectionArray.length !== reorderedSectionArray.length || sectionDictionary.oldFindings.length) {
    setSectionsReorderedState(true);
  }
  return reorderedSectionArray;
};

const SummaryOfFindingsAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, formInputsStateRef, fhirEntryState, setFhirEntryState, history, changeFormState,
  adaptationReportState }) => {

  let fhirJson = resourceState.resourceJson;

  let defaultType = compositionTypeCodeSummaryOfFindings;

  const [sectionsReorderedState, setSectionsReorderedState] = useState(false);

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        let newSection = reorderSections(JSON.parse(JSON.stringify(prevState.section)), setSectionsReorderedState);
        return {
          ...prevState,
          "type": prevState.type || defaultType,
          "section": newSection
        };
      });
    }
  }, []);

  useEffect(() => {
    if (sectionsReorderedState) {
      if (adaptationReportState?.adaptOn) {
        alert("Sections have been reordered. Before proceeding with Adapting this Resource, it is suggested to edit the Resource or Clone the Resource, then edit it to reorder sections, then proceed with Adaptation.");
      } else {
        alert("Sections have been reordered. Clicking Update is suggested before further editing.");
      }
    }
  }, [sectionsReorderedState]);

  let startingCustomizedState = { "customized": false, "userid": "", "username": "" };
  if (fhirJson.useContext?.length > 0) {
    for (const useContext of fhirJson.useContext) {
      if (useContext.code?.system === "http://terminology.hl7.org/CodeSystem/usage-context-type" && useContext.code.code === "user" &&
        useContext.valueCodeableConcept?.text === "FEvIR Platform User") {
        startingCustomizedState = {
          "customized": true,
          "userid": globalContext.userState.id,
          "username": globalContext.userState.name
        };
      }
    }
  }

  const [openEditModalState, setOpenEditModalState] = useState({ "openModal": false });
  const [openAddTableRowModalState, setOpenAddTableRowModalState] = useState({ "openModal": false });
  const [customizedState, setCustomizedState] = useState(startingCustomizedState);
  const [needToRefreshState, setNeedToRefreshState] = useState(false);
  const [needToRefresh2State, setNeedToRefresh2State] = useState(false);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      if (!fhirJson.section) {
        fhirJson.section = [];
      }
      loadSourceJsonWithRatingDictionaryFunction(resourceState, globalContext, setSourceJsonState, setResourceState, customizedState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, []);

  useEffect(() => {
    if (resourceState.sectionChanged === true) {
      submitUpdatedFhirResource(null, "Composition", fhirJson.id, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
    }
  }, [resourceState.section]);

  const refreshResourceJson = async () => {
    if (needToRefreshState) {
      let response = await getTargetResourceRatingDictionary(resourceState.targetResources, globalContext);
      if (response?.success && response.targetResourceRatingDictionary) {
        let ratingDictionary = response.targetResourceRatingDictionary;
        setResourceState(prevState => {
          let newSection = JSON.parse(JSON.stringify(prevState.section));
          let loadedSection = newSection[1].section.map(section => {
            if (section.focus?.reference) {
              let targetfoi = section.focus.reference.replace("EvidenceVariable/", "");
              if (!targetfoi) {
                let targetfli;
                if (section.focus?.identifier?.system === "https://fevir.net/FLI" && section.focus.identifier.value) {
                  targetfli = section.focus.identifier.value;
                }
                for (const foiKey in ratingDictionary) {
                  if (ratingDictionary[foiKey].fli == targetfli) {
                    targetfoi = foiKey;
                  }
                }
              }
              if (ratingDictionary[targetfoi]) {
                let entry = ratingDictionary[targetfoi];
                if (entry.exists === false) {
                  section.section[8] = {
                    "title": "Relative Importance",
                    "code": sectionCodeRelativeImportance,
                    "emptyReason": emptyReasonNotStarted
                  }
                }
                if (entry.exists === true) {
                  section.section[8] = {
                    "title": "Relative Importance",
                    "code": sectionCodeRelativeImportance,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + entry.desirabilityDisplay + " " + entry.quantityValue.toString() + "%</div>"
                    },
                    "entry": [
                      {
                        "reference": "ArtifactAssessment/" + targetfoi, //TODO
                        "type": "ArtifactAssessment",
                        "display": entry.targettitle //TODO
                      }
                    ]
                  }
                }
              }
            }
            return section;
          });
          newSection[1].section = loadedSection;
          return {
            ...prevState,
            "section": newSection,
            "ratingDictionary": ratingDictionary
          };
        });
        setNeedToRefreshState(false);
      }
    }
  }

  useEffect(() => {
    refreshResourceJson();
  }, [needToRefreshState]);

  const refreshResourceJson2 = () => {
    if (needToRefresh2State) {
      setResourceState(prevState => {
        let baselineRiskResources = identifyBaselineRiskResources(prevState.section[1].section);
        return { ...prevState, "baselineRiskResources": baselineRiskResources }
      });
      setNeedToRefresh2State(false);
    }
  }

  useEffect(() => {
    refreshResourceJson2();
  }, [needToRefresh2State]);

  let tableData = createCompositionTableDict(resourceState);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      <div>
        <h3 id="summary">Summary</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='string' elementName='title' fieldLabel='Title'
            startingValue={resourceState.title} setResourceState={setResourceState} />
          <DataEntry asArray={true} datatype='Annotation' elementName='note'
            startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
            fieldLabel='Note' startingValue={resourceState.note} setResourceState={setResourceState} />
          <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
            startCollapsed
            startingValue={resourceState.type} setResourceState={setResourceState} />
          <DataEntry asArray={true} datatype='CodeableConcept' elementName='category' fieldLabel='Category'
            startCollapsed
            startingValue={resourceState.category} setResourceState={setResourceState} />
          <DataEntry asArray={true} datatype='Reference' elementName='subject' fieldLabel='Subject'
            startCollapsed
            startingValue={resourceState.subject} setResourceState={setResourceState} />
          <DataEntry datatype='Reference' elementName='encounter' fieldLabel='Encounter'
            startCollapsed
            startingValue={resourceState.encounter} setResourceState={setResourceState} />
          <h4>Narrative Summary</h4>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
              startCollapsed
              startingValue={resourceState.text} setResourceState={setResourceState} />
          </div>
        </div>
        <h3 id="customization">Customization</h3>
        {customizedState.customized === false && <>
          &nbsp;&nbsp;&nbsp;
          <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
            content="Customize this Summary of Findings" positive
            onClick={async () => {
              setCustomizedState({
                "customized": true,
                "userid": globalContext.userState.id,
                "username": globalContext.userState.name
              });
              let response = await createCustomizedSummaryOfFindings(resourceState, globalContext);
              if (response?.success && response.newFOI) {
                let newUrl = "/resources/Composition/" + response.newFOI;
                history.push('/');
                history.push(newUrl);
              }
            }}
          />
          <br />   <br />
        </>}
        {(customizedState.customized === true) && <>
          {resourceState?.targetResources ?
            <>
              <RateRelativeImportance globalContext={globalContext}
                previousVersionLoaded={fhirEntryState.previousVersionLoaded}
                resourceState={resourceState} setResourceState={setResourceState}
                setNeedToRefreshState={setNeedToRefreshState} />
              <br />
              <ChangeBaselineRiskEstimates globalContext={globalContext}
                previousVersionLoaded={fhirEntryState.previousVersionLoaded}
                setNeedToRefresh2State={setNeedToRefresh2State}
                resourceState={resourceState} setResourceState={setResourceState} />
            </>
            :
            <>
              <span onClick={() => {
                console.log("clicked")
                console.log(resourceState)
                loadSourceJsonWithRatingDictionaryFunction(resourceState, globalContext, setSourceJsonState, setResourceState, customizedState);
              }}>Reload Source JSON</span>
            </>
          }
        </>
        }
        <h3 id="table-view">Table View</h3>
        <TableView tableData={tableData} openAddTableRowModalState={openAddTableRowModalState}
          setOpenAddTableRowModalState={setOpenAddTableRowModalState} openEditModalState={openEditModalState}
          setOpenEditModalState={setOpenEditModalState}
          setCustomizedState={setCustomizedState} resourceState={resourceState} setResourceState={setResourceState}
          globalContext={globalContext} sourceJsonState={sourceJsonState} />
        <br />
        <h3 id="column-definition">Column Definition</h3>
        <div style={{ marginLeft: "24px" }}>
          <ColumnsSectionDataEntry startingValue={resourceState.section} setResourceState={setResourceState} />
        </div>
        <h3 id="section-definition">Section Definition</h3>
        <div style={{ marginLeft: "24px" }}>
          <OutcomesSectionDataEntry startingValue={resourceState.section[1].section} setResourceState={setResourceState} />
        </div>
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

export {
  SummaryOfFindingsAuthor, checkIfStatisticAbsoluteOrRelative, codeMatchesPercentage, codeMatchesProportion, codeMatchesRelativeRisk,
  codeMatchesRiskDifference, extractControlEventRateFromStatistic, generateSectionTextForEffectEstimate,
  generateSectionTextForPercentage, updateResourceWithModifiedRisks
};