import React, { memo, useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import {
  emptyReasonNotStarted, emptyReasonValueSet, emptyReasonCodeableConceptSet, sectionCodeInterventionDescription,
  sectionCodeInterventionGroup, sectionCodeComparatorDescription, sectionCodeComparatorGroup, sectionCodeGroupAssignment, sectionCodeSubgroup,
  sectionCodeIntroduction, sectionCodePopulation, sectionCodeIntervention, sectionCodeComparator, sectionCodeResearchStudy, sectionCodeMethods,
  sectionCodeBaselineMeasures, sectionCodeParticipantFlow, sectionCodeOutcomeMeasures, sectionCodeDiscussion, sectionCodeReferences,
  sectionCodeCompetingInterests, sectionCodeAcknowledgements, sectionCodeAppendices,
  compositionTypeCodeComparativeEvidenceReport,
  emptyTextNoData,
  usageContextCodeEvidenceCommunication,
  compositionTypeCodeBaselineMeasureReport, compositionTypeCodeParticipantFlowReport, compositionTypeCodeOutcomeMeasureReport,
  sectionCodeGroups, sectionCodeVariables, sectionCodeResults,
  compositionTypeCodeSummaryOfFindings,
  sectionCodeSummaryOfFindings, sectionCodeAssertion, sectionCodeCertaintyOfEvidence,
  sectionCodeResultWithInterventionVsComparator, sectionCodeResultWithInterventionAloneCalculated,
  sectionCodeResultWithInterventionAlone, sectionCodeResultWithComparatorAlone,
  sectionCodeSampleSize, sectionCodeOutcomeMeasure, sectionCodeColumnHeaders
} from './CodeSystemLookup';
import { clearDivWrapper, DisplayFromFHIR } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { generateCompositionSummary, generateGroupSummary, generateSubgroupsSummary, generateResearchStudySummary } from './GenerateNarrativeSummaryFunctions';
import { getFoiFromReference, getFoisFromReference } from './ResourceDictionaryFunctions';
import { autoEditEmptyReason, DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { ButtonWithConfirmModal } from './ConfirmModal';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { EditTheSectionButton } from './NavigationCompositionSectionSegment';
import submitToFevirServer from './SubmitToFevirServer';
import { getStartingNarrativeFromStartingValue } from './NarrativeEntry';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } });
}

let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];

const ExposureSectionsDataEntry = ({ startingValue, setResourceState, topSection,
  sourceJsonState, globalContext, setSourceJsonState,
  fullResourceState, setFullResourceState }) => {
  if (!Array.isArray(startingValue)) {
    startingValue = null;
  }
  let startingSubsection0;
  let startingSubsection1;
  let startingSubsection2;
  let startingDescriptionEntry = null;
  let startingGroupEntry = null;
  let startingInterventionSubgroupsEntry = [];
  let startingComparatorSubgroupsEntry = [];
  if (topSection === "Intervention") {
    try {
      startingSubsection0 = startingValue[0];
    } catch {
      startingSubsection0 = {
        "title": "Intervention Description",
        "code": sectionCodeInterventionDescription,
        "emptyReason": emptyReasonNotStarted
      }
    }
    try {
      startingSubsection1 = startingValue[1];
    } catch {
      startingSubsection1 = {
        "title": "Intervention Group",
        "code": sectionCodeInterventionGroup,
        "emptyReason": emptyReasonNotStarted
      }
    }
    if (sourceJsonState?.groupReferences?.subpopulations?.length) {
      try {
        startingSubsection2 = startingValue[2];
        if (!startingSubsection2) {
          startingSubsection2 = {
            "title": "Intervention Groups for Population Subgroups",
            "code": sectionCodeSubgroup,
            "emptyReason": emptyReasonNotStarted
          }
        }
      } catch {
        startingSubsection2 = {
          "title": "Intervention Groups for Population Subgroups",
          "code": sectionCodeSubgroup,
          "emptyReason": emptyReasonNotStarted
        }
      }
    }
  } else if (topSection === "Comparator") {
    try {
      startingSubsection0 = startingValue[0];
    } catch {
      startingSubsection0 = {
        "title": "Comparator Description",
        "code": sectionCodeComparatorDescription,
        "emptyReason": emptyReasonNotStarted
      }
    }
    try {
      startingSubsection1 = startingValue[1];
    } catch {
      startingSubsection1 = {
        "title": "Comparator Group",
        "code": sectionCodeComparatorGroup,
        "emptyReason": emptyReasonNotStarted
      }
    }
    if (sourceJsonState?.groupReferences?.subpopulations?.length) {
      try {
        startingSubsection2 = startingValue[2];
        if (!startingSubsection2) {
          startingSubsection2 = {
            "title": "Comparator Groups for Population Subgroups",
            "code": sectionCodeSubgroup,
            "emptyReason": emptyReasonNotStarted
          }
        }
      } catch {
        startingSubsection2 = {
          "title": "Comparator Groups for Population Subgroups",
          "code": sectionCodeSubgroup,
          "emptyReason": emptyReasonNotStarted
        }
      }
    }
  }

  let startingExposureSectionArray = [
    {
      "title": topSection + " Description",
      "code": {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": topSection.toLowerCase() + "-description",
            "display": topSection + " Description"
          }
        ]
      },
      "emptyReason": emptyReasonNotStarted
    },
    {
      "title": topSection + " Group",
      "code": {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": topSection.toLowerCase() + "-group",
            "display": topSection + " Group"
          }
        ]
      },
      "emptyReason": emptyReasonNotStarted
    }
  ];
  if (Array.isArray(startingValue)) {
    startingExposureSectionArray = JSON.parse(JSON.stringify(startingValue));
  }

  if (startingExposureSectionArray[0]?.entry) {
    startingDescriptionEntry = startingExposureSectionArray[0].entry[0];
  }
  if (startingExposureSectionArray[1]?.entry) {
    startingGroupEntry = startingExposureSectionArray[1].entry[0];
  }
  if (startingExposureSectionArray[2]?.entry) {
    if (topSection === "Intervention") {
      startingInterventionSubgroupsEntry = startingExposureSectionArray[2].entry;
    } else if (topSection === "Comparator") {
      startingComparatorSubgroupsEntry = startingExposureSectionArray[2].entry;
    }
  }
  if (!startingExposureSectionArray[2] && sourceJsonState?.groupReferences?.subpopulations?.length) {
    startingExposureSectionArray.push(startingSubsection2);
  }

  const [exposureSectionArrayState, setExposureSectionArrayState] = useState(JSON.parse(JSON.stringify(startingExposureSectionArray || null)));
  const [referencesEntryState, setReferencesEntryState] = useState({
    "descriptionReference": startingDescriptionEntry,
    "groupReference": startingGroupEntry,
    "interventionSubgroupReferences": startingInterventionSubgroupsEntry,
    "comparatorSubgroupReferences": startingComparatorSubgroupsEntry
  });

  useEffect((() => {
    let newArray = JSON.parse(JSON.stringify(exposureSectionArrayState));
    if (Array.isArray(newArray) && (newArray.length === 2 || newArray.length === 3)) {
      setResourceState(prevState => { return { ...prevState, section: newArray } })
    }
  }), [exposureSectionArrayState])

  return <div>
    <h3>{topSection} Description</h3>
    <ExposureSectionDataEntry subsectionCode="Description" startingValue={startingSubsection0}
      setResourceState={setExposureSectionArrayState} resourceState={exposureSectionArrayState}
      topSection={topSection} globalContext={globalContext}
      referencesEntryState={referencesEntryState} setReferencesEntryState={setReferencesEntryState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fullResourceState={fullResourceState} setFullResourceState={setFullResourceState} />
    <h3>{topSection} Group</h3>
    <ExposureSectionDataEntry subsectionCode="Group" startingValue={startingSubsection1}
      setResourceState={setExposureSectionArrayState} resourceState={exposureSectionArrayState}
      topSection={topSection} globalContext={globalContext}
      referencesEntryState={referencesEntryState} setReferencesEntryState={setReferencesEntryState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fullResourceState={fullResourceState} setFullResourceState={setFullResourceState} />
    {startingSubsection2 && <>
      <h3>{topSection} Groups for Population Subgroups</h3>
      <ExposureSubpopulationsSectionDataEntry startingValue={startingSubsection2}
        setResourceState={setExposureSectionArrayState} resourceState={exposureSectionArrayState}
        topSection={topSection} globalContext={globalContext}
        referencesEntryState={referencesEntryState} setReferencesEntryState={setReferencesEntryState}
        sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
        fullResourceState={fullResourceState} setFullResourceState={setFullResourceState}
      />
    </>}
  </div>
}

const ExposureSectionDataEntry = memo(({ subsectionCode, startingValue, resourceState, setResourceState, topSection,
  sourceJsonState, globalContext, referencesEntryState, setReferencesEntryState,
  setSourceJsonState, fullResourceState, setFullResourceState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingExposureSection = {
    "title": subsectionCode + " Section",
    "code": { "text": subsectionCode },
    "author": [],
    "focus": {},
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  if (startingValue) {
    if (startingValue.title) { startingExposureSection.title = startingValue.title; }
    if (startingValue.code) { startingExposureSection.code = startingValue.code; }
    if (startingValue.author) { startingExposureSection.author = startingValue.author; }
    if (startingValue.focus) { startingExposureSection.focus = startingValue.focus; }
    if (startingValue.text) { startingExposureSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingExposureSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingExposureSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingExposureSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingExposureSection.section = startingValue.section; }
  } else {
    startingExposureSection = {
      "title": topSection + " " + subsectionCode + " Section",
      "code": { "text": topSection + " " + subsectionCode }
    };
  }

  const [exposureSectionState, setExposureSectionState] = useState(JSON.parse(JSON.stringify(startingExposureSection || null)));
  const [firstRenderState, setFirstRenderState] = useState(true);
  const [interventionGroupValuesState, setInterventionGroupValuesState] = useState(null);

  let generateInterventionGroupValues = () => {
    if (sourceJsonState?.groupReferences?.totalGroup && sourceJsonState.groupReferences.interventionDescription) {
      return {
        "combinationMethod": "all-of",
        "characteristic": [
          {
            "description": "inclusion in " + sourceJsonState.groupReferences.totalGroup.display,
            "code": { "text": "Member of" },
            "valueReference": sourceJsonState.groupReferences.totalGroup,
            "exclude": false
          },
          {
            "description": "exposed to " + sourceJsonState.groupReferences.interventionDescription.display,
            "code": { "text": "Exposed to" },
            "valueReference": sourceJsonState.groupReferences.interventionDescription,
            "exclude": false
          }
        ]
      }
    }
  }

  let generateComparatorGroupValues = () => {
    if (sourceJsonState?.groupReferences?.totalGroup && sourceJsonState.groupReferences.comparatorDescription) {
      return {
        "combinationMethod": "all-of",
        "characteristic": [
          {
            "description": "inclusion in " + sourceJsonState.groupReferences.totalGroup.display,
            "code": { "text": "Member of" },
            "valueReference": sourceJsonState.groupReferences.totalGroup,
            "exclude": false
          },
          {
            "description": "exposed to " + sourceJsonState.groupReferences.comparatorDescription.display,
            "code": { "text": "Exposed to" },
            "valueReference": sourceJsonState.groupReferences.comparatorDescription,
            "exclude": false
          }
        ]
      }
    }
  }

  useEffect(() => {
    if (topSection === "Intervention") {
      setInterventionGroupValuesState(generateInterventionGroupValues());
    } else if (topSection === "Comparator") {
      setInterventionGroupValuesState(generateComparatorGroupValues());
    }
  }, [sourceJsonState]);

  useEffect(() => {
    if (subsectionCode === "Description" && referencesEntryState?.descriptionReference) {
      setExposureSectionState(prevState => {
        return { ...prevState, entry: [JSON.parse(JSON.stringify(referencesEntryState.descriptionReference))] };
      });
    }
    if (subsectionCode === "Group" && referencesEntryState?.groupReference) {
      setExposureSectionState(prevState => {
        return { ...prevState, entry: [JSON.parse(JSON.stringify(referencesEntryState.groupReference))] };
      });
    }
  }, [referencesEntryState])

  useEffect(() => {
    if (resourceState) {
      let newSectionArray = JSON.parse(JSON.stringify(resourceState));
      let newSection = JSON.parse(JSON.stringify(exposureSectionState));
      newSection = autoEditEmptyReason(newSection);
      if (!newSectionArray || !Array.isArray(newSectionArray) || newSectionArray.length === 0) {
        if (topSection === "Intervention") {
          newSectionArray = [
            {
              "title": "Intervention Description",
              "code": sectionCodeInterventionDescription,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "title": "Intervention Group",
              "code": sectionCodeInterventionGroup,
              "emptyReason": emptyReasonNotStarted
            }
          ];
        }
        if (topSection === "Comparator") {
          newSectionArray = [
            {
              "title": "Comparator Description",
              "code": sectionCodeComparatorDescription,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "title": "Comparator Group",
              "code": sectionCodeComparatorGroup,
              "emptyReason": emptyReasonNotStarted
            }
          ];
        }
      }
      if (subsectionCode === "Description") {
        newSectionArray[0] = newSection;
      } else if (subsectionCode === "Group") {
        if (newSectionArray.length === 1) {
          newSectionArray.push(newSection);
        } else {
          newSectionArray[1] = newSection;
        }
      }
      setResourceState(newSectionArray);
    }
  }, [exposureSectionState]);

  if (subsectionCode === "Description") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={topSection + " " + subsectionCode + ' Section Summary'}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={exposureSectionState.entry} globalContext={globalContext}
          resourceType="Group" resourceDictionary={resourceDictionary}
          startingValue={exposureSectionState.text} setResourceState={setExposureSectionState} />
        <h3>{subsectionCode} Section Content: Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the {topSection}.</h3>
        <div style={{ marginLeft: "24px" }}>
          {referencesEntryState && <DataEntry datatype='Reference' elementName='descriptionReference'
            fieldLabel='Group Resource (ExposureDefinition or ComparatorDefinition Profile)'
            startingValue={referencesEntryState.descriptionReference} referencedResourceTypes={['Group']}
            startingResourceType="Group" selectProfile={['ExposureDefinition', 'ComparatorDefinition']}
            startCollapsed enableCreation={true}
            setResourceState={setReferencesEntryState} setSourceJsonState={setSourceJsonState}
            globalContext={globalContext}
            fullResourceState={fullResourceState} />}
        </div>
        <br />
        {((!exposureSectionState.section || exposureSectionState.section.length === 0) &&
          (!exposureSectionState.entry || exposureSectionState.entry.length === 0) &&
          (!exposureSectionState.text || exposureSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={exposureSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setExposureSectionState} />
            </div>
          </>}
      </div>
    </>
  } else if (subsectionCode === "Group") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={topSection + " " + subsectionCode + ' Section Summary'}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={exposureSectionState.entry} globalContext={globalContext}
          resourceType="Group" resourceDictionary={resourceDictionary}
          startingValue={exposureSectionState.text} setResourceState={setExposureSectionState} />
        <h3>{subsectionCode} Section Content: Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the {topSection}.</h3>
        <div style={{ marginLeft: "24px" }}>
          {referencesEntryState && <DataEntry datatype='Reference' elementName='groupReference'
            fieldLabel='Group Resource (ExposureGroup or ComparatorGroup Profile)'
            startingValue={referencesEntryState.groupReference} referencedResourceTypes={['Group']}
            startingResourceType="Group" selectProfile={['ExposureGroup', 'ComparatorGroup']}
            startCollapsed enableCreation={true}
            addElementValues={interventionGroupValuesState}
            setResourceState={setReferencesEntryState} setSourceJsonState={setSourceJsonState}
            globalContext={globalContext}
            fullResourceState={fullResourceState} />}
        </div>
        <br />
        {((!exposureSectionState.section || exposureSectionState.section.length === 0) &&
          (!exposureSectionState.entry || exposureSectionState.entry.length === 0) &&
          (!exposureSectionState.text || exposureSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={exposureSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setExposureSectionState} />
            </div>
          </>}
      </div>
    </>
  } else {
    return <>
      <p>Unrecognized section detected with sectionCode:</p>
      <p>{subsectionCode}</p>
    </>
  }
})

const submitFhirResources = async (resourceSetToSubmit, globalContext) => {
  let createdFois;
  resourceSetToSubmit = resourceSetToSubmit.map(resource => {
    if (resource.fhirJson) {
      return resource;
    } else {
      return {"fhirJson": resource};
    }
  });
  const body = {
    'functionid': 'bulksubmitfhirresource',
    'idToken': '',
    'resourceSet': JSON.stringify(resourceSetToSubmit, null, 2),
    'tool': "ComparativeEvidenceReportAuthoringTool",
    'title': "",
    'status': "active",
  };

  let response = await submitToFevirServer(globalContext, 120000, body, true, false);
  if (response?.success) {
    if (response.warnings?.length > 0) {
      console.log(response.warnings.join(", "));
    }
    alert("All resources successfully submitted.");
    createdFois = response.fois;
  } else {
    alert("Problem submitting resources.");
  }
  return createdFois;
}

const createExposureSubgroups = async (topSection, globalContext, interventionSubpopulationsGroupValuesState, setReferencesEntryState) => {
  let newGroupResources = interventionSubpopulationsGroupValuesState.map(item => { return item });
  let submit = true;
  let resourceSetToSubmit = [];
  try {
    for (let x in newGroupResources) {
      let resource = newGroupResources[x];
      if (!resource.resourceType) {
        resource.resourceType = "Group";
      }
      let title = "";
      if (resource.title && typeof resource.title === "string" && resource.title.trim()) {
        title = resource.title;
      } else if (resource.name) {
        if (typeof resource.name === "string" && resource.name.trim()) {
          title = resource.name;
        } else if (Array.isArray(resource.name) && resource.name.length > 0 && resource.name[0].text && resource.name[0].text.trim() !== "") {
          title = resource.name[0].text;
        }
      }
      if (title === "") {
        title = resource.resourceType + " submitted as Resource " + (parseInt(x) + 1) + " of " + newGroupResources.length;
      }
      let resourceEntry = { "fhirJson": resource, "title": title };
      resourceSetToSubmit.push(resourceEntry);
    }
  } catch {
    submit = false;
    alert("Improper JSON format");
  }
  let createdFois;
  if (submit) {
    createdFois = await submitFhirResources(resourceSetToSubmit, globalContext);
  } else {
    console.log("There's a problem with the JSON format. It needs to be an array of resources.")
  }
  if (createdFois) {
    if (topSection === "Intervention") {
      setReferencesEntryState(prevState => {
        return {
          ...prevState, 'interventionSubgroupReferences': createdFois.map(foi => {
            return {
              "reference": "Group/" + foi,
              "type": "Group"
            }
          })
        }
      });
    } else if (topSection === "Comparator") {
      setReferencesEntryState(prevState => {
        return {
          ...prevState, 'comparatorSubgroupReferences': createdFois.map(foi => {
            return {
              "reference": "Group/" + foi,
              "type": "Group"
            }
          })
        }
      });
    }
  }
};

const ExposureSubpopulationsSectionDataEntry = ({ startingValue, resourceState, setResourceState, topSection,
  sourceJsonState, globalContext, referencesEntryState, setReferencesEntryState,
  setSourceJsonState, fullResourceState, setFullResourceState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingExposureSection = {
    "title": "Subgroups Section",
    "code": sectionCodeSubgroup,
    "author": [],
    "focus": {},
    "text": emptyTextNoData,
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  if (startingValue) {
    if (startingValue.title) { startingExposureSection.title = startingValue.title; }
    if (startingValue.code) { startingExposureSection.code = startingValue.code; }
    if (startingValue.author) { startingExposureSection.author = startingValue.author; }
    if (startingValue.focus) { startingExposureSection.focus = startingValue.focus; }
    if (startingValue.text) { startingExposureSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingExposureSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingExposureSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingExposureSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingExposureSection.section = startingValue.section; }
  } else {
    startingExposureSection = {
      "title": topSection + " Subgroups Section",
      "code": sectionCodeSubgroup
    };
  }

  const [exposureSectionState, setExposureSectionState] = useState(JSON.parse(JSON.stringify(startingExposureSection || null)));
  const [firstRenderState, setFirstRenderState] = useState(true);
  const [interventionSubpopulationsGroupValuesState, setInterventionSubpopulationsGroupValuesState] = useState(null);

  let generateSubpopulationsInterventionGroupValues = () => {
    if (sourceJsonState?.groupReferences?.subpopulations?.length && sourceJsonState.groupReferences.interventionDescription) {
      return sourceJsonState.groupReferences.subpopulations.map(subgroup => {
        return {
          "resourceType": "Group",
          "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/exposure-group"]
          },
          "useContext": [
            {
              code: usageContextCodeEvidenceCommunication,
              valueCodeableConcept: {
                coding: [
                  {
                    system: "https://fevir.net/resources/CodeSystem/179423",
                    code: "ExposureGroup", display: "ExposureGroup"
                  }
                ]
              }
            },
            {
              code: {
                "system": "http://terminology.hl7.org/CodeSystem/usage-context-type",
                "code": "program",
                "display": "Program"
              },
              valueReference: {
                "reference": "Composition/" + resourceState.id,
                "type": "Composition",
                "display": resourceState.title || resourceState.name
              }
            }
          ],
          "title": subgroup.display + " exposed to " + sourceJsonState.groupReferences.interventionDescription.display,
          "membership": "conceptual",
          "combinationMethod": "all-of",
          "characteristic": [
            {
              "description": "inclusion in " + subgroup.display,
              "code": { "text": "Member of" },
              "valueReference": subgroup,
              "exclude": false
            },
            {
              "description": "exposed to " + sourceJsonState.groupReferences.interventionDescription.display,
              "code": { "text": "Exposed to" },
              "valueReference": sourceJsonState.groupReferences.interventionDescription,
              "exclude": false
            }
          ]
        }
      });

    }
  }

  let generateSubpopulationsComparatorGroupValues = () => {
    if (sourceJsonState?.groupReferences?.subpopulations?.length && sourceJsonState.groupReferences.comparatorDescription) {
      return sourceJsonState.groupReferences.subpopulations.map(subgroup => {
        return {
          "resourceType": "Group",
          "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/comparator-group"]
          },
          "useContext": [
            {
              code: usageContextCodeEvidenceCommunication,
              valueCodeableConcept: {
                coding: [
                  {
                    system: "https://fevir.net/resources/CodeSystem/179423",
                    code: "ComparatorGroup", display: "ExpoCoComparatorGroupmparatorGroupsureGroup"
                  }
                ]
              }
            },
            {
              code: {
                "system": "http://terminology.hl7.org/CodeSystem/usage-context-type",
                "code": "program",
                "display": "Program"
              },
              valueReference: {
                "reference": "Composition/" + resourceState.id,
                "type": "Composition",
                "display": resourceState.title || resourceState.name
              }
            }
          ],
          "title": subgroup.display + " exposed to " + sourceJsonState.groupReferences.comparatorDescription.display,
          "membership": "conceptual",
          "combinationMethod": "all-of",
          "characteristic": [
            {
              "description": "inclusion in " + subgroup.display,
              "code": { "text": "Member of" },
              "valueReference": subgroup,
              "exclude": false
            },
            {
              "description": "exposed to " + sourceJsonState.groupReferences.comparatorDescription.display,
              "code": { "text": "Exposed to" },
              "valueReference": sourceJsonState.groupReferences.comparatorDescription,
              "exclude": false
            }
          ]
        }
      });
    }
  }

  useEffect(() => {
    if (topSection === "Intervention") {
      setInterventionSubpopulationsGroupValuesState(generateSubpopulationsInterventionGroupValues());
    } else if (topSection === "Comparator") {
      setInterventionSubpopulationsGroupValuesState(generateSubpopulationsComparatorGroupValues());
    }
  }, [sourceJsonState]);

  useEffect(() => {
    if (topSection === "Intervention" && referencesEntryState?.interventionSubgroupReferences?.length) {
      setExposureSectionState(prevState => {
        return { ...prevState, entry: JSON.parse(JSON.stringify(referencesEntryState.interventionSubgroupReferences)) };
      });
    }
    if (topSection === "Comparator" && referencesEntryState?.comparatorSubgroupReferences?.length) {
      setExposureSectionState(prevState => {
        return { ...prevState, entry: JSON.parse(JSON.stringify(referencesEntryState.comparatorSubgroupReferences)) };
      });
    }
  }, [referencesEntryState])

  useEffect(() => {
    if (firstRenderState) {
      if (sourceJsonState) {
        if (topSection === "Intervention") {
          setInterventionSubpopulationsGroupValuesState(generateSubpopulationsInterventionGroupValues());
        } else if (topSection === "Comparator") {
          setInterventionSubpopulationsGroupValuesState(generateSubpopulationsComparatorGroupValues());
        }
      }
      setFirstRenderState(false);
    } else if (resourceState) {
      let newSectionArray = JSON.parse(JSON.stringify(resourceState));
      let newSection = JSON.parse(JSON.stringify(exposureSectionState));
      newSection = autoEditEmptyReason(newSection);
      if (!newSectionArray || !Array.isArray(newSectionArray) || newSectionArray.length === 0) {
        if (topSection === "Intervention") {
          newSectionArray = [
            {
              "title": "Intervention Description",
              "code": sectionCodeInterventionDescription,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "title": "Intervention Group",
              "code": sectionCodeInterventionGroup,
              "emptyReason": emptyReasonNotStarted
            }
          ];
        }
        if (topSection === "Comparator") {
          newSectionArray = [
            {
              "title": "Comparator Description",
              "code": sectionCodeComparatorDescription,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "title": "Comparator Group",
              "code": sectionCodeComparatorGroup,
              "emptyReason": emptyReasonNotStarted
            }
          ];
        }
      }
      if (newSectionArray.length === 3) {
        newSectionArray[2] = newSection;
      } else if (newSectionArray.length === 2) {
        newSectionArray.push(newSection);
      } else {
        alert("newSectionArray should have length 2 or 3 but has length of " + newSectionArray.length);
      }
      setResourceState(newSectionArray);
    }
  }, [exposureSectionState]);

  return <>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={topSection + ' Subgroups Section Summary'}
        generateSummaryFromArrayFunction={generateSubgroupsSummary}
        sectionEntry={exposureSectionState.entry} globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={exposureSectionState.text} setResourceState={setExposureSectionState} />
      {(interventionSubpopulationsGroupValuesState && referencesEntryState &&
        ((topSection === "Intervention" && !referencesEntryState.interventionSubgroupReferences.length) ||
          (topSection === "Comparator" && !referencesEntryState.comparatorSubgroupReferences.length))) && <>
          <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
            content={"Create Group Resources for all " + topSection + " subgroups"} positive
            onClick={() => {
              createExposureSubgroups(topSection, globalContext, interventionSubpopulationsGroupValuesState, setReferencesEntryState);
            }}
          />
          <br /><br /></>}
      <h3>Subgroups Section Content: Create or identify the Group Resource(s) (ExposureGroup or ComparatorGroup Profile) for the {topSection}.</h3>
      <div style={{ marginLeft: "24px" }}>
        {referencesEntryState && <DataEntry asArray={true} datatype='Reference'
          elementName={topSection === "Intervention" ? "interventionSubgroupReferences" : "comparatorSubgroupReferences"}
          fieldLabel='Group Resource(s) (ExposureGroup or ComparatorGroup Profile)'
          startingValue={topSection === "Intervention" ? referencesEntryState.interventionSubgroupReferences : referencesEntryState.comparatorSubgroupReferences}
          referencedResourceTypes={['Group']}
          startingResourceType="Group" selectProfile={['ExposureGroup', 'ComparatorGroup']}
          startCollapsed enableCreation={true}
          setResourceState={setReferencesEntryState} setSourceJsonState={setSourceJsonState}
          globalContext={globalContext}
          fullResourceState={fullResourceState} />}
      </div>
      <br />
      {((!exposureSectionState.section || exposureSectionState.section.length === 0) &&
        (!exposureSectionState.entry || exposureSectionState.entry.length === 0) &&
        (!exposureSectionState.text || exposureSectionState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={exposureSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setExposureSectionState} />
          </div>
        </>}
    </div>
  </>
}

const SingleMeasureReportEntryDataEntry = ({ startingValue, setResourceState, setProfile, fieldLabel,
  groupReferences, compositionId, compositionTitle }) => {
  let startingEntryInstance = { "reference": "", "type": "Composition", "display": "" };
  if (Array.isArray(startingValue) && startingValue[0]) {
    if (startingValue[0].reference) {
      startingEntryInstance.reference = startingValue[0].reference;
    }
    if (startingValue[0].display) {
      startingEntryInstance.display = startingValue[0].display;
    }
  }
  let startingType;
  if (setProfile === "OutcomeMeasureReport") {
    startingType = compositionTypeCodeOutcomeMeasureReport;
  } else if (setProfile === "ParticipantFlowReport") {
    startingType = compositionTypeCodeParticipantFlowReport;
  } else if (setProfile === "BaselineMeasureReport") {
    startingType = compositionTypeCodeBaselineMeasureReport;
  }
  let startingRelatesTo = [
    {
      "type": "part-of",
      "resourceReference": {
        "reference": "Composition/" + compositionId,
        "type": "Composition",
        "display": compositionTitle
      }
    }
  ];
  let startingSection = [
    {
      "title": "Groups",
      "code": sectionCodeGroups,
      "section": [
        {
          "title": "Total Group",
          "code": sectionCodePopulation,
          "emptyReason": emptyReasonNotStarted
        },
        {
          "title": "Intervention Group",
          "code": sectionCodeInterventionGroup,
          "emptyReason": emptyReasonNotStarted
        },
        {
          "title": "Comparator Group",
          "code": sectionCodeComparatorGroup,
          "emptyReason": emptyReasonNotStarted
        },
        {
          "title": "Group Assignment",
          "code": sectionCodeGroupAssignment,
          "emptyReason": emptyReasonNotStarted
        }
      ]
    },
    {
      "title": "Variables (Measures)",
      "code": sectionCodeVariables,
      "emptyReason": emptyReasonNotStarted
    },
    {
      "title": "Results",
      "code": sectionCodeResults,
      "emptyReason": emptyReasonNotStarted
    }
  ];
  if (groupReferences) {
    if (groupReferences.totalGroup) {
      startingSection[0].section[0].entry = [groupReferences.totalGroup];
      delete startingSection[0].section[0].emptyReason;
    }
    if (groupReferences.interventionGroup) {
      startingSection[0].section[1].entry = [groupReferences.interventionGroup];
      delete startingSection[0].section[1].emptyReason;
    }
    if (groupReferences.comparatorGroup) {
      startingSection[0].section[2].entry = [groupReferences.comparatorGroup];
      delete startingSection[0].section[2].emptyReason;
    }
    if (groupReferences.groupAssignment) {
      startingSection[0].section[3].entry = [groupReferences.groupAssignment];
      delete startingSection[0].section[3].emptyReason;
    }
  }

  const [entryInstanceState, setEntryInstanceState] = useState({ "instance": JSON.parse(JSON.stringify(startingEntryInstance)) });

  useEffect(() => {
    if (entryInstanceState.instance && JSON.stringify(entryInstanceState.instance) !== JSON.stringify(startingEntryInstance)) {
      setResourceState(prevState => {
        return {
          ...prevState,
          "entry": [JSON.parse(JSON.stringify(entryInstanceState.instance))]
        };
      });
    }
  }, [entryInstanceState]);

  return <DataEntry datatype='Reference' elementName='instance' fieldLabel={fieldLabel}
    startingValue={entryInstanceState.instance} referencedResourceTypes={['Composition']}
    startingResourceType="Composition" setProfile={setProfile}
    addElementValues={{
      type: startingType || "",
      relatesTo: startingRelatesTo || "",
      section: startingSection || ""
    }}
    startCollapsed enableCreation={true}
    setResourceState={setEntryInstanceState} />
}

const SummaryOfFindingsEntryDataEntry = ({ startingValue, setResourceState,
  groupReferences, compositionId, compositionTitle }) => {
  let startingEntryInstance0 = { "reference": "", "type": "Composition", "display": "" };
  if (Array.isArray(startingValue)) {
    if (startingValue[0]) {
      if (startingValue[0].reference) {
        startingEntryInstance0.reference = startingValue[0].reference;
      }
      if (startingValue[0].display) {
        startingEntryInstance0.display = startingValue[0].display;
      }
    }
  }
  let startingRelatesTo = [
    {
      "type": "part-of",
      "resourceReference": {
        "reference": "Composition/" + compositionId,
        "type": "Composition",
        "display": compositionTitle
      }
    }
  ];
  let startingSection = [
    {
      "title": "Column Headers",
      "code": sectionCodeColumnHeaders,
      "section": [
        {
          "title": "Outcome Measure",
          "code": sectionCodeOutcomeMeasure,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Outcome</p></div>"
          }
        },
        {
          "title": "Sample Size",
          "code": sectionCodeSampleSize,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Sample Size</p></div>"
          }
        },
        {
          "title": "Result Without Treatment",
          "code": sectionCodeResultWithComparatorAlone,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result Without Treatment</p></div>"
          }
        },
        {
          "title": "Result With Treatment--Observed",
          "code": sectionCodeResultWithInterventionAlone,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Observed)</p></div>"
          }
        },
        {
          "title": "Result With Treatment--Derived",
          "code": sectionCodeResultWithInterventionAloneCalculated,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Calculated)</p></div>"
          }
        },
        {
          "title": "Effect Estimate",
          "code": sectionCodeResultWithInterventionVsComparator,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Effect Estimate</p></div>"
          }
        },
        {
          "title": "Certainty of Evidence",
          "code": sectionCodeCertaintyOfEvidence,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Certainty of Evidence</p></div>"
          }
        },
        {
          "title": "Assertion",
          "code": sectionCodeAssertion,
          "text": {
            "status": "generated",
            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>What this means</p></div>"
          }
        }
      ]
    },
    {
      "title": "Summary of Findings",
      "code": sectionCodeSummaryOfFindings,
      "section": [],
      "emptyReason": emptyReasonNotStarted
    },
    {
      "title": "Groups",
      "code": sectionCodeGroups,
      "section": [
        {
          "title": "Total Group",
          "code": sectionCodePopulation,
          "emptyReason": emptyReasonNotStarted
        },
        {
          "title": "Intervention Group",
          "code": sectionCodeInterventionGroup,
          "emptyReason": emptyReasonNotStarted
        },
        {
          "title": "Comparator Group",
          "code": sectionCodeComparatorGroup,
          "emptyReason": emptyReasonNotStarted
        },
        {
          "title": "Group Assignment",
          "code": sectionCodeGroupAssignment,
          "emptyReason": emptyReasonNotStarted
        }
      ]
    },
    {
      "title": "Variables (Measures)",
      "code": sectionCodeVariables,
      "emptyReason": emptyReasonNotStarted
    }
  ];
  if (groupReferences) {
    if (groupReferences.totalGroup) {
      startingSection[2].section[0].entry = [groupReferences.totalGroup];
      delete startingSection[0].section[0].emptyReason;
    }
    if (groupReferences.interventionGroup) {
      startingSection[2].section[1].entry = [groupReferences.interventionGroup];
      delete startingSection[0].section[1].emptyReason;
    }
    if (groupReferences.comparatorGroup) {
      startingSection[2].section[2].entry = [groupReferences.comparatorGroup];
      delete startingSection[0].section[2].emptyReason;
    }
    if (groupReferences.groupAssignment) {
      startingSection[2].section[3].entry = [groupReferences.groupAssignment];
      delete startingSection[0].section[3].emptyReason;
    }
  }

  const [entryInstanceState, setEntryInstanceState] = useState({
    "instance": JSON.parse(JSON.stringify(startingEntryInstance0))
  });

  useEffect(() => {
    if (entryInstanceState.instance && JSON.stringify(entryInstanceState.instance) !== JSON.stringify(startingEntryInstance0)) {
      setResourceState(prevState => {
        let newEntry = [JSON.parse(JSON.stringify(entryInstanceState.instance))];
        return {
          ...prevState,
          "entry": newEntry
        };
      });
    }
  }, [entryInstanceState]);

  return <div>
    <DataEntry datatype='Reference' elementName='instance'
      fieldLabel='Composition Resource (SummaryOfFindings Profile)'
      startingValue={entryInstanceState.instance} referencedResourceTypes={['Composition']}
      startingResourceType="Composition" setProfile='SummaryOfFindings'
      addElementValues={{
        type: compositionTypeCodeSummaryOfFindings || "",
        relatesTo: startingRelatesTo || "",
        section: startingSection || ""
      }}
      startCollapsed enableCreation={true}
      setResourceState={setEntryInstanceState} />
  </div>
}

const NarrativeEntry = ({ elementName, fieldLabel, startingValue = {}, setResourceState,
  generateSummaryFunction, generateSummaryFromArrayFunction,
  sectionEntry, sectionFocus, resourceType, resourceDictionary, globalContext }) => {
  const [narrativeState, setNarrativeState] = useState(null);
  const [narrativeDivState, setNarrativeDivState] = useState(null);
  const [startingNarrativeDivState, setStartingNarrativeDivState] = useState(null);
  const [startingNarrativeState, setStartingNarrativeState] = useState(null);
  const [narrativeIsGeneratedState, setNarrativeIsGeneratedState] = useState();

  useEffect(() => {
    let [startingNarrative, startingDivValue] = getStartingNarrativeFromStartingValue(startingValue);
    setNarrativeState(JSON.parse(JSON.stringify(startingNarrative)));
    setStartingNarrativeState(JSON.parse(JSON.stringify(startingValue)));
    setNarrativeDivState(startingDivValue);
    setStartingNarrativeDivState(startingDivValue);
    setNarrativeIsGeneratedState(startingNarrative.status === "generated");
  }, [])

  const checkIfStatusAdditional = () => {
    return narrativeState.status === "additional";
  };

  const updateNarrativeStates = async () => {
    if (generateSummaryFunction) {
      let entryFoi = getFoiFromReference(sectionEntry || sectionFocus, resourceType, resourceDictionary);
      if (entryFoi) {
        let narrativeDivValue = clearDivWrapper(await generateSummaryFunction(entryFoi, resourceDictionary, globalContext));
        setNarrativeIsGeneratedState(true);
        setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
        setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
      }
    } else if (generateSummaryFromArrayFunction) {
      let entryFoiList = getFoisFromReference(sectionEntry, resourceType, resourceDictionary);
      if (entryFoiList?.length) {
        let narrativeDivValue = clearDivWrapper(await generateSummaryFromArrayFunction(entryFoiList, resourceDictionary, globalContext));
        setNarrativeIsGeneratedState(true);
        setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
        setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
      }
    }
  }

  useEffect((() => {
    if (narrativeState && JSON.stringify(startingNarrativeState) !== JSON.stringify(narrativeState)) {
      let newNarrative = {};
      if (narrativeState.extension) { newNarrative.extension = narrativeState.extension; }
      if (narrativeState.status) { newNarrative.status = narrativeState.status; }
      if (narrativeState.div) { newNarrative.div = narrativeState.div; }
      if (Object.keys(newNarrative).length === 0 ||
        (newNarrative.status === "empty" && !clearDivWrapper(newNarrative.div) && !newNarrative.extension)) {
        newNarrative = null;
      }
      handleChange(elementName, newNarrative, setResourceState);
    }
  }), [narrativeState]);

  useEffect((() => {
    if (narrativeDivState && startingNarrativeDivState.divText !== narrativeDivState.divText) {
      if ((narrativeDivState.divText || narrativeDivState.divText === "0") &&
        narrativeDivState.divText !== '[No data]' && narrativeDivState.divText !== '[No data.]' &&
        narrativeDivState.divText !== '[No Narrative summary found in the Composition Resource.]' &&
        narrativeDivState.divText !== '<p>[No data]</p>' && narrativeDivState.divText !== '<p>[No data.]</p>' &&
        narrativeDivState.divText !== '<p>[No Narrative summary found in the Composition Resource.]</p>' &&
        narrativeDivState.divText !== '<p><br></p>') {
        if (narrativeIsGeneratedState) {
          setNarrativeState(prevState => { return { ...prevState, "status": "generated", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
          setNarrativeIsGeneratedState(false);
        } else {
          setNarrativeState(prevState => { return { ...prevState, "status": "additional", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
        }
      } else {
        setNarrativeState(prevState => { return { ...prevState, "status": "empty", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
      }
    }
  }), [narrativeDivState]);

  return <div>
    <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
      message="This will overwrite the manually edited summary with a generated summary."
      conditionForConfirmModal={checkIfStatusAdditional}
      functionIfConfirmed={updateNarrativeStates}
      disabled={((!sectionEntry || !sectionEntry[0] || (!sectionEntry[0].reference && !sectionEntry[0].identifier)) && !sectionFocus)} />
    {narrativeState?.status && <p style={{ marginBottom: "0px" }}>
      <b>Natural language summary: </b>
      (status: {(narrativeState.status === "additional") ? "manually edited" : narrativeState.status})
    </p>}
    <div style={{ marginLeft: "24px" }}>
      {narrativeDivState?.divText && <DataEntry datatype="xhtml" elementName='divText' fieldLabel={fieldLabel}
        startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState} />}
    </div>
  </div>
}

const TopSectionDataEntry = ({ sectionCode, startingValue, resourceState, setResourceState, globalContext,
  sourceJsonState, setSourceJsonState, history, setFhirEntryState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let groupReferences = sourceJsonState.groupReferences;

  const [topSectionState, setTopSectionState] = useState(null);
  const [firstRenderState, setFirstRenderState] = useState(true);

  useEffect(() => {
    if (firstRenderState) {
      let startingTopSection = {
        "title": sectionCode + " Section",
        "code": { "text": sectionCode },
        "author": [],
        "focus": {},
        "text": emptyTextNoData,
        "orderedBy": {},
        "entry": [],
        "emptyReason": {},
        "section": []
      };
      if (startingValue) {
        if (startingValue.title) { startingTopSection.title = startingValue.title; }
        if (startingValue.code) { startingTopSection.code = startingValue.code; }
        if (startingValue.author) { startingTopSection.author = startingValue.author; }
        if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
        if (startingValue.text) { startingTopSection.text = startingValue.text; }
        if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
        if (startingValue.entry) {
          startingTopSection.entry = startingValue.entry;
          if (sectionCode === "Population") {
            if (startingValue.entry.length > 1) {
              alert("The Population section has more than one entry value. Only the first entry value will be kept if the content is edited.");
            }
          }
        }
        if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
        if (startingValue.section) {
          startingTopSection.section = startingValue.section;
        }
      } else {
        startingTopSection = {
          "title": sectionCode + " Section",
          "code": { "text": sectionCode }
        };
      }
      setTopSectionState(JSON.parse(JSON.stringify(startingTopSection)));
    }
  }, []);

  let relatesTo = [
    {
      type: "part-of",
      resourceReference: {
        "reference": "Composition/" + resourceState.id,
        "type": "Composition",
        "display": resourceState.title || resourceState.name
      }
    }
  ];

  const addPopulationSubgroupSection = async (sectionTitle, profile, compositionType, sourceJsonState, globalContext) => {
    let entry = [];
    let newCompositionArray = [];
    if (sourceJsonState?.groupReferences?.subpopulations?.length) {
      newCompositionArray = sourceJsonState.groupReferences.subpopulations.map((subgroup, index) => {
        if (profile === "SummaryOfFindings") {
          return {
            "resourceType": "Composition",
            "meta": {
              "profile": [profile]
            },
            "type": compositionType,
            "relatesTo": relatesTo,
            "title": sectionTitle + " for " + subgroup.display,
            "section": [
              {
                "title": "Column Headers",
                "code": sectionCodeColumnHeaders,
                "section": [
                  {
                    "title": "Outcome Measure",
                    "code": sectionCodeOutcomeMeasure,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Outcome</p></div>"
                    }
                  },
                  {
                    "title": "Sample Size",
                    "code": sectionCodeSampleSize,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Sample Size</p></div>"
                    }
                  },
                  {
                    "title": "Result Without Treatment",
                    "code": sectionCodeResultWithComparatorAlone,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result Without Treatment</p></div>"
                    }
                  },
                  {
                    "title": "Result With Treatment--Observed",
                    "code": sectionCodeResultWithInterventionAlone,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Observed)</p></div>"
                    }
                  },
                  {
                    "title": "Result With Treatment--Derived",
                    "code": sectionCodeResultWithInterventionAloneCalculated,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Calculated)</p></div>"
                    }
                  },
                  {
                    "title": "Effect Estimate",
                    "code": sectionCodeResultWithInterventionVsComparator,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Effect Estimate</p></div>"
                    }
                  },
                  {
                    "title": "Certainty of Evidence",
                    "code": sectionCodeCertaintyOfEvidence,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Certainty of Evidence</p></div>"
                    }
                  },
                  {
                    "title": "Assertion",
                    "code": sectionCodeAssertion,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>What this means</p></div>"
                    }
                  }
                ]
              },
              {
                "title": "Summary of Findings",
                "code": sectionCodeSummaryOfFindings,
                "section": [],
                "emptyReason": emptyReasonNotStarted
              },
              {
                "title": "Groups",
                "code": sectionCodeGroups,
                "section": [
                  {
                    "title": "Total Group",
                    "code": sectionCodePopulation,
                    "entry": [subgroup]
                  },
                  {
                    "title": "Intervention Group",
                    "code": sectionCodeInterventionGroup,
                    "entry": sourceJsonState.groupReferences.interventionsubgroups?.[index] ? [sourceJsonState.groupReferences.interventionsubgroups[index]] : [],
                    "emptyReason": sourceJsonState.groupReferences.interventionsubgroups?.[index] ? "" : emptyReasonNotStarted
                  },
                  {
                    "title": "Comparator Group",
                    "code": sectionCodeComparatorGroup,
                    "entry": sourceJsonState.groupReferences.comparatorsubgroups?.[index] ? [sourceJsonState.groupReferences.comparatorsubgroups[index]] : [],
                    "emptyReason": sourceJsonState.groupReferences.comparatorsubgroups?.[index] ? "" : emptyReasonNotStarted
                  },
                  {
                    "title": "Group Assignment",
                    "code": sectionCodeGroupAssignment,
                    "emptyReason": emptyReasonNotStarted
                  }
                ]
              },
              {
                "title": "Variables (Measures)",
                "code": sectionCodeVariables,
                "emptyReason": emptyReasonNotStarted
              }
            ]
          }   
        }
        return {
          "resourceType": "Composition",
          "meta": {
            "profile": [profile]
          },
          "type": compositionType,
          "relatesTo": relatesTo,
          "title": sectionTitle + " for " + subgroup.display,
          "section": [
            {
              "title": "Groups",
              "code": sectionCodeGroups,
              "section": [
                {
                  "title": "Total Group",
                  "code": sectionCodePopulation,
                  "entry": [subgroup]
                },
                {
                  "title": "Intervention Group",
                  "code": sectionCodeInterventionGroup,
                  "entry": sourceJsonState.groupReferences.interventionsubgroups?.[index] ? [sourceJsonState.groupReferences.interventionsubgroups[index]] : [],
                  "emptyReason": sourceJsonState.groupReferences.interventionsubgroups?.[index] ? "" : emptyReasonNotStarted
                },
                {
                  "title": "Comparator Group",
                  "code": sectionCodeComparatorGroup,
                  "entry": sourceJsonState.groupReferences.comparatorsubgroups?.[index] ? [sourceJsonState.groupReferences.comparatorsubgroups[index]] : [],
                  "emptyReason": sourceJsonState.groupReferences.comparatorsubgroups?.[index] ? "" : emptyReasonNotStarted
                },
                {
                  "title": "Group Assignment",
                  "code": sectionCodeGroupAssignment,
                  "emptyReason": emptyReasonNotStarted
                }
              ]
            },
            {
              "title": "Variables (Measures)",
              "code": sectionCodeVariables,
              "emptyReason": emptyReasonNotStarted
            },
            {
              "title": "Results",
              "code": sectionCodeResults,
              "emptyReason": emptyReasonNotStarted
            }
          ]
        }
      });
    }
    let createdFois;
    if (newCompositionArray.length) {
      createdFois = await submitFhirResources(newCompositionArray, globalContext);
    }
    alert("Click Update on the left before editing the " + sectionTitle + " section.");
    if (createdFois) {
      entry = createdFois.map(foi => {
        return {
          "reference": "Composition/" + foi,
          "type": "Composition"
        }
      });
    }

    setTopSectionState(prevState => {
      return {
        ...prevState, section: [{
          "title": sectionTitle,
          "code": sectionCodeSubgroup,
          "entry": entry
        }]
      };
    });
  };

  useEffect(() => {
    if (firstRenderState && topSectionState) {
      setFirstRenderState(false);
    } else if (!firstRenderState && topSectionState) {
      let newResource = JSON.parse(JSON.stringify(resourceState));
      let newSection = JSON.parse(JSON.stringify(topSectionState));
      newSection = autoEditEmptyReason(newSection);
      if (sectionCode === "Population") {
        newResource.section[1] = newSection;
      } else if (sectionCode === "Intervention") {
        newResource.section[2] = newSection;
      } else if (sectionCode === "Comparator") {
        newResource.section[3] = newSection;
      } else if (sectionCode === "Research Study") {
        newResource.section[4] = newSection;
      } else if (sectionCode === "Baseline Measures") {
        newResource.section[6] = newSection;
      } else if (sectionCode === "Participant Flow") {
        newResource.section[7] = newSection;
      } else if (sectionCode === "Outcomes") {
        newResource.section[8] = newSection;
      } else if (sectionCode === "Summary of Findings") {
        newResource.section[9] = newSection;
      }
      setResourceState(newResource);
    }
  }, [topSectionState]);

  const [expandAuthorEntryState, setExpandAuthorEntryState] = useState(false);

  if (topSectionState) {
    if (sectionCode === "Population") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateGroupSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="Group" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Group Resource (StudyGroup Profile) for the Population.</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='Group Resource (StudyGroup Profile)'
              startingValue={topSectionState.entry} referencedResourceTypes={['Group']}
              startingResourceType="Group" setProfile="StudyGroup"
              startCollapsed enableCreation={true}
              setResourceState={setTopSectionState} setSourceJsonState={setSourceJsonState}
              globalContext={globalContext}
              fullResourceState={resourceState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
          <br />
          {topSectionState.section?.[0] ? <>
            <h3>Population Subgroups</h3>
            <div style={{ marginLeft: "24px" }}>
              <EditTheSectionButton theThis="the" sectionName="Population Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[1, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
              <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
              <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
              <br />
              {topSectionState.section[0].orderedBy && <span>
                <b>Ordered by: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
              </span>}
              {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                topSectionState.section[0].entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {topSectionState.section[0].emptyReason && <span>
                <b>Empty Reason: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
              </span>}
              <EditTheSectionButton theThis="this" sectionName="Population Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[1, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
            </div>
          </> : <div>
            <span onClick={() => { addPopulationSubgroupSection("Population Subgroups"); }} >
              <p><b>Click to add Population Subgroups. Update the Resource before editing the Population Subgroups section.</b></p>
            </span>
          </div>}
        </div>
      </>
    } else if (sectionCode === "Intervention" || sectionCode === "Comparator") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <ExposureSectionsDataEntry startingValue={topSectionState.section}
            setResourceState={setTopSectionState} globalContext={globalContext}
            sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            fullResourceState={resourceState} setFullResourceState={setResourceState}
            topSection={sectionCode} />
        </div>
      </>
    } else if (sectionCode === "Research Study") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateResearchStudySummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="ResearchStudy" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the ResearchStudy Resource containing the Study Design information.</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='ResearchStudy Resource'
              startingValue={topSectionState.entry} referencedResourceTypes={['ResearchStudy']}
              startingResourceType="ResearchStudy"
              startCollapsed startEmptyArrayClosed enableCreation={true}
              setResourceState={setTopSectionState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
        </div>
      </>
    } else if (sectionCode === "Baseline Measures") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateCompositionSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="Composition" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Composition Resource containing the Baseline Measure Report.</h3>
          <div style={{ marginLeft: "24px" }}>
            <SingleMeasureReportEntryDataEntry startingValue={topSectionState.entry} setResourceState={setTopSectionState}
              setProfile="BaselineMeasureReport" fieldLabel='Composition Resource (BaselineMeasureReport Profile)'
              groupReferences={groupReferences} compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
          <br />
          {topSectionState.section?.[0] ? <>
            <h3>Baseline Measure Reports for Subgroups</h3>
            <div style={{ marginLeft: "24px" }}>
              <EditTheSectionButton theThis="the" sectionName="Baseline Measure Reports for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[6, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
              <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
              <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
              <br />
              {topSectionState.section[0].orderedBy && <span>
                <b>Ordered by: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
              </span>}
              {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                topSectionState.section[0].entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {topSectionState.section[0].emptyReason && <span>
                <b>Empty Reason: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
              </span>}
              <EditTheSectionButton theThis="this" sectionName="Baseline Measure Reports for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[6, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
            </div>
          </> : <div>
            <span onClick={() => {
              addPopulationSubgroupSection("Baseline Measure Reports for Subgroups",
                "http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report", compositionTypeCodeBaselineMeasureReport,
                sourceJsonState, globalContext);
            }} >
              <p><b>Click to add Baseline Measure Reports for Subgroups. Update the Resource before editing the Baseline Measure Reports for Subgroups section.</b></p>
            </span>
          </div>}
        </div>
      </>
    } else if (sectionCode === "Participant Flow") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateCompositionSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="Composition" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Composition Resource containing the Participant Flow Report.</h3>
          <div style={{ marginLeft: "24px" }}>
            <SingleMeasureReportEntryDataEntry startingValue={topSectionState.entry} setResourceState={setTopSectionState}
              setProfile="ParticipantFlowReport" fieldLabel='Composition Resource (ParticipantFlowReport Profile)'
              groupReferences={groupReferences} compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
          <br />
          {topSectionState.section?.[0] ? <>
            <h3>Participant Flow Reports for Subgroups</h3>
            <div style={{ marginLeft: "24px" }}>
              <EditTheSectionButton theThis="the" sectionName="Participant Flow Reports for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[7, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
              <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
              <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
              <br />
              {topSectionState.section[0].orderedBy && <span>
                <b>Ordered by: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
              </span>}
              {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                topSectionState.section[0].entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {topSectionState.section[0].emptyReason && <span>
                <b>Empty Reason: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
              </span>}
              <EditTheSectionButton theThis="this" sectionName="Participant Flow Reports for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[7, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
            </div>
          </> : <div>
            <span onClick={() => {
              addPopulationSubgroupSection("Participant Flow Reports for Subgroups",
                "http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report", compositionTypeCodeParticipantFlowReport,
                sourceJsonState, globalContext);
            }} >
              <p><b>Click to add Participant Flow Reports for Subgroups. Update the Resource before editing the Participant Flow Reports for Subgroups section.</b></p>
            </span>
          </div>}
        </div>
      </>
    } else if (sectionCode === "Outcomes") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateCompositionSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="Composition" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Composition Resource(s) containing the Outcome Measure Report.</h3>
          <div style={{ marginLeft: "24px" }}>
            <SingleMeasureReportEntryDataEntry startingValue={topSectionState.entry} setResourceState={setTopSectionState}
              setProfile="OutcomeMeasureReport" fieldLabel='Composition Resource (OutcomeMeasureReport Profile)'
              groupReferences={groupReferences} compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
          <br />
          {topSectionState.section?.[0] ? <>
            <h3>Outcome Measure Reports for Subgroups</h3>
            <div style={{ marginLeft: "24px" }}>
              <EditTheSectionButton theThis="the" sectionName="Outcome Measure Reports for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[8, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
              <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
              <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
              <br />
              {topSectionState.section[0].orderedBy && <span>
                <b>Ordered by: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
              </span>}
              {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                topSectionState.section[0].entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {topSectionState.section[0].emptyReason && <span>
                <b>Empty Reason: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
              </span>}
              <EditTheSectionButton theThis="this" sectionName="Outcome Measure Reports for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[8, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
            </div>
          </> : <div>
            <span onClick={() => {
              addPopulationSubgroupSection("Outcome Measure Reports for Subgroups",
                "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report", compositionTypeCodeOutcomeMeasureReport,
                sourceJsonState, globalContext);
            }} >
              <p><b>Click to add Outcome Measure Reports for Subgroups. Update the Resource before editing the Outcome Measure Reports for Subgroups section.</b></p>
            </span>
          </div>}
        </div>
      </>
    } else if (sectionCode === "Summary of Findings") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateCompositionSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="Composition" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Composition Resource(s) containing the Summary of Findings Report.</h3>
          <div style={{ marginLeft: "24px" }}>
            <SummaryOfFindingsEntryDataEntry startingValue={topSectionState.entry} setResourceState={setTopSectionState}
              groupReferences={groupReferences} compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
            <br />
            {((!topSectionState.section || topSectionState.section.length === 0) &&
              (!topSectionState.entry || topSectionState.entry.length === 0) &&
              (!topSectionState.text || topSectionState.text.status === "empty")) && <>
                <h3>Explain why empty</h3>
                <div style={{ marginLeft: "24px" }}>
                  <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                    startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                    codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                    setResourceState={setTopSectionState} />
                </div>
              </>}
            <br />
            {topSectionState.section?.[0] ? <>
              <h3>Summary of Findings Reports for Subgroups</h3>
              <div style={{ marginLeft: "24px" }}>
                <EditTheSectionButton theThis="the" sectionName="Summary of Findings Reports for Subgroups"
                  sectionSelected={topSectionState.section[0]} sectionPathIndexes={[9, 0]}
                  resourceStateId={resourceState.id} history={history}
                  setFhirEntryState={setFhirEntryState} />
                <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
                <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
                <br />
                {topSectionState.section[0].orderedBy && <span>
                  <b>Ordered by: </b>
                  <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
                </span>}
                {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                  topSectionState.section[0].entry.map((entry, entryIndex) => {
                    return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                  })}
                {topSectionState.section[0].emptyReason && <span>
                  <b>Empty Reason: </b>
                  <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
                </span>}
                <EditTheSectionButton theThis="this" sectionName="Summary of Findings Reports for Subgroups"
                  sectionSelected={topSectionState.section[0]} sectionPathIndexes={[9, 0]}
                  resourceStateId={resourceState.id} history={history}
                  setFhirEntryState={setFhirEntryState} />
              </div>
            </> : <div>
              <span onClick={() => {
                addPopulationSubgroupSection("Summary of Findings Reports for Subgroups",
                  "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings", compositionTypeCodeSummaryOfFindings,
                  sourceJsonState, globalContext);
              }} >
                <p><b>Click to add Summary of Findings Reports for Subgroups. Update the Resource before editing the Summary of Findings Reports for Subgroups section.</b></p>
              </span>
            </div>}
          </div>
        </div>
      </>
    } else {
      return <>
        <p>Unrecognized section detected with sectionCode:</p>
        <p>{sectionCode}</p>
      </>
    }
  } else {
    return <></>;
  }
}

const reorderSections = (sectionArray, setSectionsReorderedState) => {
  let emptyIntroduction = {
    "title": "Introduction",
    "code": sectionCodeIntroduction,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyPopulation = {
    "title": "Population",
    "code": sectionCodePopulation,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyIntervention = {
    "title": "Intervention",
    "code": sectionCodeIntervention,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyComparator = {
    "title": "Comparator",
    "code": sectionCodeComparator,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyResearchStudy = {
    "title": "Research Study",
    "code": sectionCodeResearchStudy,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": sectionCodeMethods,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyBaselineMeasures = {
    "title": "Baseline Measures",
    "code": sectionCodeBaselineMeasures,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyParticipantFlow = {
    "title": "Participant Flow",
    "code": sectionCodeParticipantFlow,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyOutcomes = {
    "title": "Outcomes",
    "code": sectionCodeOutcomeMeasures,
    "emptyReason": emptyReasonNotStarted
  };
  let emptySummaryOfFindings = {
    "title": "Summary of Findings",
    "code": sectionCodeSummaryOfFindings,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": sectionCodeDiscussion,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyReferences = {
    "title": "References Section",
    "code": sectionCodeReferences,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": sectionCodeCompetingInterests,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": sectionCodeAcknowledgements,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": sectionCodeAppendices,
    "emptyReason": emptyReasonNotStarted
  };
  let sectionDictionary = {
    introduction: emptyIntroduction,
    population: emptyPopulation,
    intervention: emptyIntervention,
    comparator: emptyComparator,
    researchStudy: emptyResearchStudy,
    methods: emptyMethods,
    baselineMeasures: emptyBaselineMeasures,
    participantFlow: emptyParticipantFlow,
    outcomeMeasures: emptyOutcomes,
    summaryOfFindings: emptySummaryOfFindings,
    discussion: emptyDiscussion,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "population" || sectionCode === "Population") {
      sectionDictionary.population = section;
    } else if (sectionCode === "intervention" || sectionCode === "Intervention") {
      sectionDictionary.intervention = section;
    } else if (sectionCode === "comparator" || sectionCode === "Comparator") {
      sectionDictionary.comparator = section;
    } else if (sectionCode === "research-study" || sectionCode === "Research Study") {
      sectionDictionary.researchStudy = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "baseline-measures" || sectionCode === "Baseline Measures") {
      sectionDictionary.baselineMeasures = section;
    } else if (sectionCode === "participant-flow" || sectionCode === "Participant Flow") {
      sectionDictionary.participantFlow = section;
    } else if (sectionCode === "outcome-measures" || sectionCode === "Outcome Measures" || sectionCode === "Outcomes") {
      sectionDictionary.outcomeMeasures = section;
    } else if (sectionCode === "summary-of-findings" || sectionCode === "Summary of Findings") {
      sectionDictionary.summaryOfFindings = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.introduction,
    sectionDictionary.population,
    sectionDictionary.intervention,
    sectionDictionary.comparator,
    sectionDictionary.researchStudy,
    sectionDictionary.methods,
    sectionDictionary.baselineMeasures,
    sectionDictionary.participantFlow,
    sectionDictionary.outcomeMeasures,
    sectionDictionary.summaryOfFindings,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  if (sectionArray.length !== reorderedSectionArray.length) {
    setSectionsReorderedState(true);
  }
  return reorderedSectionArray;
};

const ComparativeEvidenceReportAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, previousVersionLoaded, history, setFhirEntryState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let defaultType = compositionTypeCodeComparativeEvidenceReport;

  const [sectionsReorderedState, setSectionsReorderedState] = useState(false);

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        let newSection = reorderSections(JSON.parse(JSON.stringify(prevState.section)), setSectionsReorderedState);
        return {
          ...prevState,
          type: prevState.type || defaultType,
          section: newSection
        };
      });
    }
  }, []);

  useEffect(() => {
    if (sectionsReorderedState) {
      if (adaptationReportState?.adaptOn) {
        alert("Sections have been reordered. Before proceeding with Adapting this Resource, it is suggested to edit the Resource or Clone the Resource, then edit it to reorder sections, then proceed with Adaptation.");
      } else {
        alert("Sections have been reordered. Clicking Update is suggested before further editing.");
      }
    }
  }, [sectionsReorderedState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded || !sourceJsonState.loaded2) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  useEffect(() => {
    setSourceJsonState(prevState => { return { ...prevState, loaded2: false } });
  }, [resourceState.section]);

  return <>{(sourceJsonState.loaded && resourceState.section?.length > 13) ?
    <div style={{ marginTop: "12px" }}>
      {adaptationReportState?.adaptOn ?
        <>
          <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <h3 id="population">Population</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={1}
              fieldLabel="Population Section Summary"
              sectionCode="section[1]:https://fevir.net/resources/CodeSystem/179423#population"
              startingValue={resourceState.section[1] || null}
              fixedTitle={"Population"} fixedCode={sectionCodePopulation} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              generateTextDivFunction={generateGroupSummary}
              editAuthor={true}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
              entryEnableCreation={true} entryStartCollapsed={true}
              entryReferencedResourceTypes={["Group"]}
              entryStartingResourceType="Group"
              entrySetProfile="StudyGroup"
              entryHeader="Create or identify the Group Resource (StudyGroup Profile) for the Population."
              editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonStartCollapsed={true}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              editSection={true}
              editableSectionCodes={false} addSectionAllowed={false}
              allowedSectionCodes={[
                sectionCodeSubgroup
              ]}
              sectionDictionary={{
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"subgroup","display":"Subgroup"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Population Subgroups",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryHeader': "Create or identify the Group Resource(s) for the Population Subgroups.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                }
              }}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <h3 id="intervention">Intervention</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={2}
              fieldLabel="Intervention Section Summary"
              sectionCode="section[2]:https://fevir.net/resources/CodeSystem/179423#intervention"
              startingValue={resourceState.section[2] || null}
              fixedTitle={"Intervention"} fixedCode={sectionCodeIntervention} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              editAuthor={true}
              noEntry={true}
              editSection={true}
              editableSectionCodes={false} addSectionAllowed={false}
              allowedSectionCodes={[
                sectionCodeInterventionDescription,
                sectionCodeInterventionGroup
              ]}
              sectionDictionary={{
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"intervention-description","display":"Intervention Description"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Intervention Description",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'generateTextDivFunction': generateGroupSummary,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryHeader': "Create or identify the Group Resource (ExposureDefinition Profile) for the Intervention Description.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                  'entrySetProfile': "ExposureDefinition",
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"intervention-group","display":"Intervention Group"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Intervention Group",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'generateTextDivFunction': generateGroupSummary,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryHeader': "Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Intervention Group.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                  'entrySelectProfile': ["ExposureGroup", "ComparatorGroup"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                }
              }}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <h3 id="comparator">Comparator</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={3}
              fieldLabel="Comparator Section Summary"
              sectionCode="section[3]:https://fevir.net/resources/CodeSystem/179423#comparator"
              startingValue={resourceState.section[3] || null}
              fixedTitle={"Comparator"} fixedCode={sectionCodeComparator} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              editAuthor={true}
              noEntry={true}
              editSection={true}
              editableSectionCodes={false} addSectionAllowed={false}
              allowedSectionCodes={[
                sectionCodeComparatorDescription,
                sectionCodeComparatorGroup
              ]}
              sectionDictionary={{
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"comparator-description","display":"Comparator Description"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Comparator Description",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'generateTextDivFunction': generateGroupSummary,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryHeader': "Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the Comparator Description.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                  'entrySelectProfile': ["ExposureDefinition", "ComparatorDefinition"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"comparator-group","display":"Comparator Group"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Comparator Group",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'generateTextDivFunction': generateGroupSummary,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryHeader': "Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Comparator Group.",
                  'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                  'entrySelectProfile': ["ExposureGroup", "ComparatorGroup"],
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                }
              }}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <h3 id="research-study">Research Study</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={4}
              fieldLabel="Research Study Section Summary"
              sectionCode="section[4]:https://fevir.net/resources/CodeSystem/179423#research-study"
              startingValue={resourceState.section[4] || null}
              fixedTitle={"Research Study"} fixedCode={sectionCodeResearchStudy} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              generateTextDivFunction={generateResearchStudySummary}
              editAuthor={true}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
              entryEnableCreation={true} entryStartCollapsed={true}
              entryReferencedResourceTypes={["ResearchStudy"]}
              entryStartingResourceType="ResearchStudy"
              entryHeader="Create or identify the ResearchStudy Resource containing the Study Design information."
              editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonStartCollapsed={true}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              editSection={true}
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <h3 id="baseline-measures">Baseline Measures</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={6}
              startingValue={resourceState.section[6] || null}
              fieldLabel="Baseline Measures"
              fixedTitle={"Baseline Measures"} fixedCode={sectionCodeBaselineMeasures}
              startCollapsed editTextDiv={true} editAuthor={true}
              emptyTextDivValue="[No Narrative summary found in the Composition Resource.]"
              generateTextDivFunction={generateCompositionSummary}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
              entryEnableCreation={true} entryStartCollapsed={true}
              entryReferencedResourceTypes={["Composition"]}
              entryStartingResourceType="Composition"
              entrySetProfile="BaselineMeasureReport"
              entryHeader="Create or identify the Composition Resource containing the Baseline Measure Report."
              editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              noSection={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
          </div>
          <h3 id="participant-flow">Participant Flow</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={7}
              startingValue={resourceState.section[7] || null}
              fieldLabel="Participant Flow"
              fixedTitle={"Participant Flow"} fixedCode={sectionCodeParticipantFlow}
              startCollapsed editTextDiv={true} editAuthor={true}
              emptyTextDivValue="[No Narrative summary found in the Composition Resource.]"
              generateTextDivFunction={generateCompositionSummary}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
              entryHeader="Create or identify the Composition Resource containing the Participant Flow Report."
              entryEnableCreation={true} entryStartCollapsed={true}
              entryReferencedResourceTypes={["Composition"]}
              entryStartingResourceType="Composition"
              entrySetProfile="ParticipantFlowReport"
              editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              noSection={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
          </div>
          <h3 id="outcomes">Outcomes</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={8}
              startingValue={resourceState.section[8] || null}
              fieldLabel="Outcomes"
              fixedTitle={"Outcome Measures"} fixedCode={sectionCodeOutcomeMeasures}
              startCollapsed editTextDiv={true} editAuthor={true}
              emptyTextDivValue="[No Narrative summary found in the Composition Resource.]"
              generateTextDivFunction={generateCompositionSummary}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
              entryEnableCreation={true} entryStartCollapsed={true}
              entryReferencedResourceTypes={["Composition"]}
              entryStartingResourceType="Composition"
              entrySetProfile="OutcomeMeasureReport"
              entryHeader="Create or identify the Composition Resource(s) containing the Outcome Measure Report."
              editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              noSection={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
          </div>
          <h3 id="summary-of-findings">Summary of Findings</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={9}
              startingValue={resourceState.section[9] || null}
              fieldLabel="Summary of Findings"
              fixedTitle={"Summary of Findings"} fixedCode={sectionCodeSummaryOfFindings}
              startCollapsed editTextDiv={true} editAuthor={true}
              emptyTextDivValue="[No Narrative summary found in the Composition Resource.]"
              generateTextDivFunction={generateCompositionSummary}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
              entryEnableCreation={true} entryStartCollapsed={true}
              entryReferencedResourceTypes={["Composition"]}
              entryStartingResourceType="Composition"
              entrySetProfile="SummaryOfFindings"
              entryHeader="Create or identify the Composition Resource(s) containing the Summary of Findings Report."
              editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              noSection={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
          </div>
          <EditDiscussion sectionIndex={10} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditReferences sectionIndex={11} previousVersionLoaded={previousVersionLoaded}
            resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditCompetingInterests sectionIndex={12} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditAcknowledgements sectionIndex={13} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditAppendices sectionIndex={14} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </>
        :
        <>
          <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState} />
          <h3 id="population">Population</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Population"
              startingValue={resourceState.section[1] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
              history={history} setFhirEntryState={setFhirEntryState} />
          </div>
          <br />
          <h3 id="intervention">Intervention</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Intervention"
              startingValue={resourceState.section[2] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
              history={history} setFhirEntryState={setFhirEntryState}
            />
          </div>
          <h3 id="comparator">Comparator</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Comparator"
              startingValue={resourceState.section[3] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
              setFhirEntryState={setFhirEntryState} history={history} />
          </div>
          <br />
          <h3 id="research-study">Research Study</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Research Study"
              startingValue={resourceState.section[4] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setFhirEntryState={setFhirEntryState} history={history} />
          </div>
          <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <h3 id="baseline-measures">Baseline Measures</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Baseline Measures"
              startingValue={resourceState.section[6] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setFhirEntryState={setFhirEntryState} history={history} />
          </div>
          <br />
          <h3 id="participant-flow">Participant Flow</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Participant Flow"
              startingValue={resourceState.section[7] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setFhirEntryState={setFhirEntryState} history={history} />
          </div>
          <br />
          <h3 id="outcomes">Outcomes</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Outcomes"
              startingValue={resourceState.section[8] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setFhirEntryState={setFhirEntryState} history={history} />
          </div>
          <br />
          <h3 id="summary-of-findings">Summary of Findings</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Summary of Findings"
              startingValue={resourceState.section[9] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setFhirEntryState={setFhirEntryState} history={history} />
          </div>
          <br />
          <EditDiscussion sectionIndex={10} resourceState={resourceState} setResourceState={setResourceState} />
          <EditReferences sectionIndex={11} previousVersionLoaded={previousVersionLoaded}
            resourceState={resourceState} setResourceState={setResourceState} />
          <EditCompetingInterests sectionIndex={12} resourceState={resourceState} setResourceState={setResourceState} />
          <EditAcknowledgements sectionIndex={13} resourceState={resourceState} setResourceState={setResourceState} />
          <EditAppendices sectionIndex={14} resourceState={resourceState} setResourceState={setResourceState} />
        </>
      }
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

const changeSectionDetailEditSettingsForComparativeEvidenceReport = (sectionDetailEditSettings, section, resourceDictionary) => {

  if (section.code?.coding?.[0]?.code === "population" || section.code?.text === "Population") {
    sectionDetailEditSettings.fixedTitle = "Population";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (StudyGroup Profile) for the Population.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySetProfile = "StudyGroup";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.addSingleSectionFixedTitleFixedCodeNoText = {
      "title": "Population Subgroups",
      "code": sectionCodeSubgroup
    };
  }
  if (section.code?.coding?.[0]?.code === "subgroup" || section.code?.text === "Subgroups" || section.code?.text === "Population Subgroups") {
    sectionDetailEditSettings.fixedTitle = "Population Subgroups";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction = generateSubgroupsSummary;
    sectionDetailEditSettings.entryFoiList = getFoisFromReference(section.entry, "Group", resourceDictionary);
    sectionDetailEditSettings.usesLoadSourceJson = true;
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource(s) for the Population Subgroups.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
    if (section.title === "Baseline Measure Reports for Subgroups") {
      sectionDetailEditSettings.fixedTitle = "Baseline Measure Reports for Subgroups";
      delete sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
      delete sectionDetailEditSettings.entryFoiList;
      sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) for the Baseline Measure Reports for Subgroups.";
      sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
      sectionDetailEditSettings.entryStartingResourceType = "Composition";
      sectionDetailEditSettings.entrySetProfile = "BaselineMeasureReport";
    } else if (section.title === "Participant Flow Reports for Subgroups") {
      sectionDetailEditSettings.fixedTitle = "Participant Flow Reports for Subgroups";
      delete sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
      delete sectionDetailEditSettings.entryFoiList;
      sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) for the Participant Flow Reports for Subgroups.";
      sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
      sectionDetailEditSettings.entryStartingResourceType = "Composition";
      sectionDetailEditSettings.entrySetProfile = "ParticipantFlowReport";
    } else if (section.title === "Outcome Measure Reports for Subgroups") {
      sectionDetailEditSettings.fixedTitle = "Outcome Measure Reports for Subgroups";
      delete sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
      delete sectionDetailEditSettings.entryFoiList;
      sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) for the Outcome Measure Reports for Subgroups.";
      sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
      sectionDetailEditSettings.entryStartingResourceType = "Composition";
      sectionDetailEditSettings.entrySetProfile = "OutcomeMeasureReport";
    } else if (section.title === "Summary of Findings Reports for Subgroups") {
      sectionDetailEditSettings.fixedTitle = "Summary of Findings Reports for Subgroups";
      delete sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
      delete sectionDetailEditSettings.entryFoiList;
      sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) for the Summary of Findings Reports for Subgroups.";
      sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
      sectionDetailEditSettings.entryStartingResourceType = "Composition";
      sectionDetailEditSettings.entrySetProfile = "SummaryOfFindings";
    }
  }
  if (section.code?.coding?.[0]?.code === "intervention" || section.code?.text === "Intervention") {
    sectionDetailEditSettings.fixedTitle = "Intervention";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "comparator" || section.code?.text === "Comparator") {
    sectionDetailEditSettings.fixedTitle = "Comparator";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "intervention-description" || section.code?.text === "Intervention Description") {
    sectionDetailEditSettings.fixedTitle = "Intervention Description";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureDefinition Profile) for the Intervention Description.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySetProfile = "ExposureDefinition";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "intervention-group" || section.code?.text === "Intervention Group") {
    sectionDetailEditSettings.fixedTitle = "Intervention Group";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Intervention Group.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySelectProfile = ["ExposureGroup", "ComparatorGroup"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.text === "Intervention Groups for Population Subgroups") {
    sectionDetailEditSettings.fixedTitle = "Intervention Groups for Population Subgroups";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction = generateSubgroupsSummary;
    sectionDetailEditSettings.entryFoiList = getFoisFromReference(section.entry, "Group", resourceDictionary);
    sectionDetailEditSettings.usesLoadSourceJson = true;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource(s) (ExposureGroup or ComparatorGroup Profile) for the Intervention Groups for Population Subgroups.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySelectProfile = ["ExposureGroup", "ComparatorGroup"];
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "comparator-description" || section.code?.text === "Comparator Description") {
    sectionDetailEditSettings.fixedTitle = "Comparator Description";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the Comparator Description.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySelectProfile = ["ExposureDefinition", "ComparatorDefinition"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "comparator-group" || section.code?.text === "Comparator Group") {
    sectionDetailEditSettings.fixedTitle = "Comparator Group";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the Comparator Group.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySelectProfile = ["ExposureGroup", "ComparatorGroup"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.text === "Comparator Groups for Population Subgroups") {
    sectionDetailEditSettings.fixedTitle = "Comparator Groups for Population Subgroups";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction = generateSubgroupsSummary;
    sectionDetailEditSettings.entryFoiList = getFoisFromReference(section.entry, "Group", resourceDictionary);
    sectionDetailEditSettings.usesLoadSourceJson = true;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource(s) (ExposureGroup or ComparatorGroup Profile) for the Comparator Groups for Population Subgroups.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySelectProfile = ["ExposureGroup", "ComparatorGroup"];
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "research-study" || section.code?.text === "Research Study") {
    sectionDetailEditSettings.fixedTitle = "Research Study";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateResearchStudySummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the ResearchStudy Resource containing the Study Design information.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ResearchStudy"];
    sectionDetailEditSettings.entryStartingResourceType = "ResearchStudy";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "baseline-measures" || section.code?.text === "Baseline Measures") {
    sectionDetailEditSettings.fixedTitle = "Baseline Measures";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No Narrative summary found in the Composition Resource.]";
    sectionDetailEditSettings.generateTextDivFunction = generateCompositionSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource containing the Baseline Measure Report.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySetProfile = "BaselineMeasureReport";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "participant-flow" || section.code?.text === "Participant Flow") {
    sectionDetailEditSettings.fixedTitle = "Participant Flow";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No Narrative summary found in the Composition Resource.]";
    sectionDetailEditSettings.generateTextDivFunction = generateCompositionSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource containing the Participant Flow Report.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySetProfile = "ParticipantFlowReport";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "outcome-measures" || section.code?.text === "Outcome Measures" || section.code?.text === "Outcomes") {
    sectionDetailEditSettings.fixedTitle = "Outcome Measures";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No Narrative summary found in the Composition Resource.]";
    sectionDetailEditSettings.generateTextDivFunction = generateCompositionSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) containing the Outcome Measure Report.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySetProfile = "OutcomeMeasureReport";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "summary-of-findings" || section.code?.text === "Summary of Findings") {
    sectionDetailEditSettings.fixedTitle = "Summary of Findings";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No Narrative summary found in the Composition Resource.]";
    sectionDetailEditSettings.generateTextDivFunction = generateCompositionSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) containing the Summary of Findings Report.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySelectProfile = "SummaryOfFindings";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }

  return sectionDetailEditSettings;
}

export { ComparativeEvidenceReportAuthor, changeSectionDetailEditSettingsForComparativeEvidenceReport, 
  NarrativeEntry, submitFhirResources };
